import React from "react";
import {Button, Form, Input, Space, Card, Tooltip, Upload, Select} from "antd";
import {MAILER_TYPES, SETTING_KEYS, SYSTEM_BOOKING_MODES} from '../../constants';
import PropTypes from "prop-types";
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    DeleteOutlined,
    QuestionCircleOutlined,
    UploadOutlined
} from "@ant-design/icons";
import fileHelper from '../../helpers/file';
import formatter from "../../helpers/formatter";
import {formLayoutColInput} from "./config";
import {Acl} from "../../helpers/acl";
import {ACL_ACTIONS} from "../../constants/acl";
import {CommonProps} from "../../store/helpers";
import {connect} from "react-redux";

export const USING_KEYS = [
    SETTING_KEYS.BOOKING.MODE,
    SETTING_KEYS.BOOKING.TIME_STEP_MINUTES,
    ...Object.values(SETTING_KEYS.BOOKING.CALENDER.MICROSOFT),
    ...Object.values(SETTING_KEYS.BOOKING.CALENDER.GOOGLE),

    SETTING_KEYS.BOOKING.AUTO_RELEASE_GRACE_MINUTES,
]

class BookingForm extends React.PureComponent {

    static propTypes = {
        settings: PropTypes.object,
        onSubmit: PropTypes.func,
        onUploadFile: PropTypes.func,
    };

    static defaultProps = {
        onSubmit: (v) => console.warn('action not register', v),
        onUploadFile: (v) => console.warn('action not register', v),
    };

    formRef = React.createRef();

    state = {
        selected_type: MAILER_TYPES.GENERIC,
        showTest: false
    }

    constructor(props) {
        super(props);
        if (props.settings) {
            if (props.settings.hasOwnProperty(SETTING_KEYS.BOOKING.MODE)) {
                this.state.selected_type = props.settings[SETTING_KEYS.BOOKING.MODE];
            }

            if (props.settings.hasOwnProperty(SETTING_KEYS.BOOKING.CALENDER.GOOGLE.CREDENTIAL_FILE)) {
                this.state[SETTING_KEYS.BOOKING.CALENDER.GOOGLE.CREDENTIAL_FILE] = props.settings[SETTING_KEYS.BOOKING.CALENDER.GOOGLE.CREDENTIAL_FILE];
            }
        }
    }

    onFinish = (form) => {
        this.props.onSubmit(form);
    }

    render() {
        const formItemLayout = {
            ...formLayoutColInput,
            onValuesChange: values => {
                if (values.hasOwnProperty(SETTING_KEYS.BOOKING.CALENDER.GOOGLE.CREDENTIAL_FILE)) {
                    this.setState({...this.state, ...values})
                }
            }
        };

        const {settings, auth} = this.props;

        const allowEditBookingMode = Acl(auth).isAllowed(ACL_ACTIONS.SETTINGS.UPDATE_BOOKING_MODE);
        const allowEditBookingIntegrationCalender = Acl(auth).isAllowed(ACL_ACTIONS.SETTINGS.UPDATE_BOOKING_INTEGRATION_CALENDER);
        const allowEditBookingAutoReleaseGracePeriod = Acl(auth).isAllowed(ACL_ACTIONS.SETTINGS.UPDATE_BOOKING_AUTO_RELEASE_GRACE_PERIOD);

        const initialValues = {};

        USING_KEYS.forEach(k => {
            initialValues[k] = settings?.[k]
        });

        const integrationFormItem = (title, formParams) => {
            const {client_id_key, client_secret_key, tenant_id_key, credential_file_key, redirect_uri_key, authority_key} = formParams;
            const isUseConfigureFile = formParams.hasOwnProperty('credential_file_key');
            const isAuthConfigured = !!this.state[credential_file_key];

            return <Card title={title} bordered={false} size="small">
                {/*{verification_code_key && <Form.Item label="Site Verification Code" name={verification_code_key}>
                    <Input/>
                </Form.Item>}*/}

                {isUseConfigureFile && <Form.Item name={credential_file_key} label={<span>
                    Authentication File&nbsp;
                    <Tooltip
                        title="You can download the credential JSON from your credential portal.">
                                <QuestionCircleOutlined/>
                            </Tooltip>
                        </span>} valuePropName="file">
                    <Space direction="horizontal">
                        <Upload listType="text" fileList={[]} beforeUpload={(file, list) => {
                            fileHelper.fileToBase64(file).then(base64 => {
                                this.formRef.current.setFieldsValue({[credential_file_key]: base64});
                                this.setState({[credential_file_key]: base64})
                            })
                            return false;
                        }}>
                            <Button icon={<UploadOutlined/>}>
                                Click to upload
                                {isAuthConfigured ?
                                    <CheckCircleOutlined style={{color: 'green'}}/> :
                                    <CloseCircleOutlined style={{color: 'red'}}/>
                                }
                            </Button>
                        </Upload>

                        {isAuthConfigured && <Button
                            danger
                            htmlType="button"
                            onClick={() => {
                                this.formRef.current.setFieldsValue({[credential_file_key]: null})
                                this.setState({[credential_file_key]: null})
                            }}
                            icon={<DeleteOutlined/>}
                        />}
                    </Space>
                </Form.Item>}

                {!isUseConfigureFile && <>
                    <Form.Item label="Client ID" name={client_id_key}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label="Client Secret" name={client_secret_key}>
                        <Input.Password/>
                    </Form.Item>
                    {authority_key && <Form.Item label="Authority" name={authority_key}>
                        <Input/>
                    </Form.Item>}
                    <Form.Item label="Tenant ID" name={tenant_id_key}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label="Redirect URI" name={redirect_uri_key}>
                        <Input/>
                    </Form.Item>
                </>}
            </Card>
        };

        return <Form {...formItemLayout} initialValues={initialValues} onFinish={this.onFinish} ref={this.formRef}>
            <Space style={{width: '100%'}} direction="vertical" size={16}>
                <Card title="General" size="small">
                    {allowEditBookingMode && <Form.Item label="Mode" name={SETTING_KEYS.BOOKING.MODE}>
                        <Select>
                            {[
                                SYSTEM_BOOKING_MODES.LOCAL,
                                SYSTEM_BOOKING_MODES.GOOGLE,
                                SYSTEM_BOOKING_MODES.MICROSOFT
                            ].map(v => <Select.Option key={v} value={v}>
                                {formatter.toDisplayBookingMode(v)}
                            </Select.Option>)}
                        </Select>
                    </Form.Item>}

                    <Form.Item label="Time span" name={SETTING_KEYS.BOOKING.TIME_STEP_MINUTES}>
                        <Select>
                            {[5, 10, 15].map(t => <Select.Option key={t} value={t}>{`${t} minutes`}</Select.Option>)}
                        </Select>
                    </Form.Item>

                    {allowEditBookingAutoReleaseGracePeriod && <Form.Item
                        label="Auto Release (no check in)"
                        name={SETTING_KEYS.BOOKING.AUTO_RELEASE_GRACE_MINUTES}
                    >
                        <Select>
                            {[0, 5, 10, 15, 20, 25, 30].map(t => <Select.Option key={t} value={t}>
                                {t === 0 ? `Disable` : `${t} minutes`}
                            </Select.Option>)}
                        </Select>
                    </Form.Item>}
                </Card>

                {allowEditBookingIntegrationCalender && <Card title="Calender" size="small">
                    {integrationFormItem('Google', {
                        // credential_file_key: SETTING_KEYS.BOOKING.CALENDER.GOOGLE.CREDENTIAL_FILE,
                        verification_code_key: SETTING_KEYS.BOOKING.CALENDER.GOOGLE.SITE_VERIFICATION_CODE,
                        client_id_key: SETTING_KEYS.BOOKING.CALENDER.GOOGLE.CLIENT_ID,
                        client_secret_key: SETTING_KEYS.BOOKING.CALENDER.GOOGLE.CLIENT_SECRET,
                        tenant_id_key: SETTING_KEYS.BOOKING.CALENDER.GOOGLE.PROJECT_ID,
                    })}

                    {integrationFormItem('Microsoft', {
                        client_id_key: SETTING_KEYS.BOOKING.CALENDER.MICROSOFT.CLIENT_ID,
                        client_secret_key: SETTING_KEYS.BOOKING.CALENDER.MICROSOFT.CLIENT_SECRET,
                        tenant_id_key: SETTING_KEYS.BOOKING.CALENDER.MICROSOFT.TENANT_ID,
                        redirect_uri_key: SETTING_KEYS.BOOKING.CALENDER.MICROSOFT.REDIRECT_URI,
                        authority_key: SETTING_KEYS.BOOKING.CALENDER.MICROSOFT.AUTHORITY
                    })}
                </Card>}

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Save
                    </Button>
                </Form.Item>
            </Space>
        </Form>
    }
}

export default connect(CommonProps, null)(BookingForm);
