import {MsalAuthProvider, LoginType} from 'react-aad-msal';
import {ConfidentialClientApplication} from '@azure/msal-node'

export const SsoMsalAuthProvider = (clientId, tenantId = null) => {
    const auth = {clientId};
    if (tenantId) {
        auth.authority = `https://login.microsoftonline.com/${tenantId}`
    }
    return new MsalAuthProvider({
        auth,
        cache: {
            cacheLocation: "localStorage",
            storeAuthStateInCookie: false
        }
    }, {
        scopes: [
            "https://graph.microsoft.com/user.read"
        ]
    }, {
        loginType: LoginType.Redirect,
    });
}

export const RoomMsalAuthProvider = (clientId, clientSecret, tenantId = null, authority = `https://login.microsoftonline.com`) => {
    const auth = {
        clientId,
        clientSecret
    };
    if (tenantId) {
        auth.authority = `${authority}/${tenantId}`;
    } else {
        auth.authority = authority;
    }
    return new ConfidentialClientApplication({auth});
}
