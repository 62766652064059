import React from "react";

import {bookingService, roomService, userService} from '../../services';

import {connect} from "react-redux";
import {
    Modal,
    Button,
    Col,
    Form,
    Row,
    Select,
} from "antd";
import PageContent from "../../components/page-content";
import {
    CalendarOutlined,
    TableOutlined,
    ReloadOutlined,
} from "@ant-design/icons";
import {CommonProps, CommonDispatcher} from '../../store/helpers';
import PropTypes from "prop-types";

import BookingCalenderScreen from "../../screens/bookings/calender";
import BookingTableScreen from "../../screens/bookings/table";
import BookingFormScreen from "../../screens/bookings/form";
import {BOOKING_STATUS, SETTING_KEYS, SYSTEM_BOOKING_MODES} from "../../constants";
import * as moment from "moment";
import {Acl} from "../../helpers/acl";
import {ACL_ACTIONS} from "../../constants/acl";

class Container extends React.PureComponent {

    static propTypes = {
        types: PropTypes.array,
    };

    static defaultProps = {};

    state = {
        pagination: {current: 1, pageSize: 50, total: 0},
        sorter: null,
        timestamps: null,
        rows: [],

        calender_view: true,
        rooms: [],
        users: [],
        show_create: false,
        edit_booking: null,
        new_booking: null
    };

    filterRef = React.createRef();

    refresh = (params = null) => {

        let {pagination, sorter, timestamps, rooms_id, room_type, users_id} = params ? params : {};

        if (!pagination) {
            pagination = this.state.pagination;
        }
        if (!sorter) {
            sorter = this.state.sorter;
        }

        if (!timestamps) {
            timestamps = this.state.timestamps;
        }

        const query = {};

        if (room_type) {
            query.room_types = room_type;
        }

        if (timestamps) {
            // use starts date for timestamp
            query.starts = timestamps;
        }

        if (rooms_id) {
            query.rooms_id = rooms_id;
        }

        if (users_id) {
            // booked by
            query.users_id = users_id;
        }

        if (sorter && Object.keys(sorter).length > 0) {
            query.sorts = `${sorter.order === 'descend' ? '-' : ''}${Array.isArray(sorter.field) ? sorter.field.join('.') : sorter.field}`;
        }

        this.setState({loading: true});
        const start = (pagination.current - 1) * pagination.pageSize;
        bookingService.list(start, pagination.pageSize, query).then(rs => {
            pagination.total = rs.total;
            this.setState({rows: rs.items, loading: false, timestamps, pagination, sorter})
        }).catch(err => {
            this.props.notificationError(err);
            this.setState({loading: false});
        });
    };

    onSubmit = (id, form) => {
        this.props.confirm(`Are you sure to ${id ? 'update' : 'create'} the booking?`, () => {
            this.setState({loading: true});
            (id ? bookingService.update(id, form) : bookingService.create(form)).then(rs => {
                this.setState({show_create: false, edit_booking: null});
                this.refresh();
            }).catch(err => {
                this.props.notificationError(err);
                this.setState({loading: false});
            });
        });
    }

    onCancel = (id) => {
        if (id) {
            this.props.confirm(`Are you sure to cancel this booking?`, () => {
                this.setState({loading: true});
                bookingService.cancel(id).then(rs => {
                    this.setState({edit_booking: null});
                    this.refresh();
                }).catch(err => {
                    this.props.notificationError(err);
                    this.setState({loading: false});
                });
            });
        }
    }

    onDelete = (id) => {
        if (id) {
            this.props.confirm(`Are you sure to delete this booking?`, () => {
                this.setState({loading: true});
                bookingService.delete(id).then(rs => {
                    this.setState({edit_booking: null});
                    this.refresh();
                }).catch(err => {
                    this.props.notificationError(err);
                    this.setState({loading: false});
                });
            });
        }
    }

    renderFilter = () => {
        const {rooms} = this.state;

        const initialValues = {
            rooms_id: []
        }

        return <Form layout="vertical" initialValues={initialValues} onValuesChange={() => this.refresh()}
                     ref={this.filterRef}>
            <Row>
                <Col span={12}>

                    <Row>
                        <Col span={18}>
                            <Form.Item label="Rooms" name="rooms_id">
                                <Select showSearch allowClear mode="multiple" filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                } onChange={() => this.refresh()}>
                                    {rooms && rooms.map(room => <Select.Option key={room.id} value={room.id}>
                                        {room.name}
                                    </Select.Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label=" ">
                                <Button icon={<ReloadOutlined/>} onClick={() => this.refresh()}/>
                            </Form.Item>
                        </Col>
                    </Row>

                </Col>
            </Row>
        </Form>
    }

    render() {
        const {app_settings, auth} = this.props;
        const {init_done, calender_view, rows, users, rooms, show_create, edit_booking, new_booking} = this.state;

        const isLocalModeBooking = app_settings?.[SETTING_KEYS.BOOKING.MODE] === SYSTEM_BOOKING_MODES.LOCAL;

        const isAllowedCreateBooking = Acl(auth).isAllowed(ACL_ACTIONS.BOOKINGS.CREATE);

        return <PageContent title="Bookings" extra={<>
            <Button key="view" onClick={() => this.setState({calender_view: !calender_view})} type="default"
                    icon={calender_view ? <TableOutlined/> : <CalendarOutlined/>}>
                {calender_view ? 'Table' : 'Calender'}
            </Button>
            {isLocalModeBooking && isAllowedCreateBooking && <Button key="add" type="primary" onClick={() => {
                this.setState({
                    show_create: true,
                    new_booking: {status: BOOKING_STATUS.NEW}
                })
            }}>
                Add
            </Button>}
        </>}>
            {init_done && <>
                {calender_view ?
                    <BookingCalenderScreen
                        bookings={rows}
                        rooms={rooms}
                        onSelect={e => {
                            if (isLocalModeBooking) {
                                this.setState({edit_booking: rows.find(b => b.id === e.id)})
                            }
                        }}
                        onChange={(params) => {
                            this.refresh({
                                room_type: params.room_type,
                                rooms_id: params.rooms_id,
                                timestamps: params.range ? [
                                    params.range.start.toISOString(),
                                    params.range.end.toISOString(),
                                ] : null
                            })
                        }}
                        onSelectSlot={({start, end}) => {
                            if (isLocalModeBooking) {
                                if (start && moment(start).isSameOrAfter(moment())) {
                                    const new_booking = {start, end, status: [BOOKING_STATUS.NEW]}
                                    this.setState({show_create: true, new_booking});
                                }
                            }
                        }}
                    /> :
                    <BookingTableScreen
                        bookings={rows}
                        rooms={rooms}
                        users={users}
                        onEdit={id => {
                            if (isLocalModeBooking) {
                                this.setState({edit_booking: rows.find(b => b.id === id)})
                            }
                        }}
                        onCancel={id => this.onCancel(id)}
                        onChange={(params) => {
                            this.refresh({
                                sorter: params.sorter,
                                pagination: params.pagination,
                                rooms_id: params.rooms_id,
                                users_id: params.users_id,
                                timestamps: params.range ? [
                                    params.range.start.toISOString(),
                                    params.range.end.toISOString(),
                                ] : null
                            })
                        }}
                    />}
            </>}

            <Modal title="Add Booking" visible={show_create} destroyOnClose={true} footer={null}
                   onCancel={() => this.setState({show_create: false})}>
                <BookingFormScreen
                    users={users}
                    rooms={rooms}
                    booking={new_booking}
                    onSubmit={(form) => this.onSubmit(null, form)}
                    getRoomFacilities={(id) => roomService.getFacilities(id)}
                />
            </Modal>

            <Modal title="Booking" visible={!!edit_booking} destroyOnClose={true} footer={null}
                   onCancel={() => this.setState({edit_booking: null})}>
                <BookingFormScreen
                    rooms={rooms}
                    users={users}
                    booking={edit_booking}
                    onDelete={() => this.onDelete(edit_booking?.id)}
                    onCancel={() => this.onCancel(edit_booking?.id)}
                    onSubmit={(form) => this.onSubmit(edit_booking?.id, form)}
                    getRoomFacilities={(id) => roomService.getFacilities(id)}
                />
            </Modal>

        </PageContent>
    }

    componentDidMount() {
        Promise.all([
            roomService.list(null, null, {is_active: true}),
            userService.list()
        ]).then(rs => {
            this.setState({init_done: true, rooms: rs[0].items, users: rs[1].items});
        }).catch(err => this.props.notificationError(err));
    }
}

export default connect(CommonProps, CommonDispatcher)(Container);
