import React from "react";

import {eventService} from '../../services';
import {connect} from "react-redux";
import {Tag, Button, Col, Form, Row, Select, Space, Table, Modal, DatePicker, Typography} from "antd";
import PageContent from "../../components/page-content";
import {DeleteOutlined, ReloadOutlined} from "@ant-design/icons";
import {CommonDispatcher} from '../../store/helpers';
import formatter from "../../helpers/formatter";
import {EVENT_SEVERITIES} from "../../constants";
import * as moment from "moment";

class Container extends React.PureComponent {

    static propTypes = {};

    static defaultProps = {};

    columns = [
        {
            dataIndex: ['timestamp'],
            title: 'Timestamp',
            sorter: true,
            width: 200,
            render: formatter.toDisplayDatetime
        },
        {
            dataIndex: 'type',
            title: 'Type',
            align: 'center',
            width: 120,
            render: v => `${v}`.toUpperCase()
        },
        {
            dataIndex: 'severity',
            title: 'Severity',
            align: 'center',
            width: 120,
            render: v => {
                const props = {};
                switch (v) {
                    case EVENT_SEVERITIES.INFO:
                        props.color = 'success';
                        break;
                    case EVENT_SEVERITIES.NOTICE:
                    case EVENT_SEVERITIES.WARNING:
                        props.color = 'warning';
                        break;
                    case EVENT_SEVERITIES.ERROR:
                    case EVENT_SEVERITIES.CRITICAL:
                    case EVENT_SEVERITIES.EMERGENCY:
                    case EVENT_SEVERITIES.ALERT:
                        props.color = 'error';
                        break;
                    default:
                        props.color = 'default';
                }

                return <Tag {...props}>{v}</Tag>
            }
        },
        {
            dataIndex: 'message',
            title: 'Message',
        },
    ];

    state = {
        pagination: {current: 1, pageSize: 50, total: 0},
        sorter: null,
        rows: [],
        showDelete: false,
        deleteBefore: moment().subtract(3, 'months')
    };

    filterRef = React.createRef();

    refresh = (params = null) => {
        let {pagination, sorter} = params ? params : {};
        if (!pagination) {
            pagination = this.state.pagination;
        }
        if (!sorter) {
            sorter = this.state.sorter;
        }

        const filter = this.filterRef.current.getFieldsValue();

        const {severities} = filter;

        const query = {};

        if (severities && severities.length > 0) {
            query.severities = severities;
        }

        if (sorter && Object.keys(sorter).length > 0) {
            query.sorts = `${sorter.order === 'descend' ? '-' : ''}${Array.isArray(sorter.field) ? sorter.field.join('.') : sorter.field}`;
        }

        if (this.props.types) {
            query.types = this.props.types
        }

        this.setState({loading: true});
        const start = (pagination.current - 1) * pagination.pageSize;
        eventService.list(start, pagination.pageSize, query).then(rs => {
            pagination.total = rs.total;
            this.setState({rows: rs.items, loading: false, pagination, sorter, filter})
        }).catch(err => {
            this.props.notificationError(err);
            this.setState({loading: false});
        });
    };

    clear = () => {
        const {deleteBefore} = this.state;
        if (deleteBefore) {
            this.setState({showDelete: false});
            const date = deleteBefore.endOf('day');
            this.props.confirm(`Are you sure to clear logs older than ${formatter.toDisplayDate(date)}? This action cannot be undone.`, () => {
                eventService.clear(date.toISOString()).then(rs => {
                    this.props.notification({message: `Removed ${rs?.deleted} record(s)`, type: 'success'});
                    this.refresh();
                }).catch(err => {
                    this.props.notificationError(err);
                    this.setState({loading: false});
                });
            });
        }
    }

    renderFilter = () => {
        const initialValues = {
            filter_type: 'name',
            keyword: '',
            severities: [],
        }

        return <Form layout="vertical" initialValues={initialValues} onValuesChange={() => this.refresh()}
                     ref={this.filterRef}>
            <Row gutter={[8, 8]}>
                <Col span={12}>
                    <Form.Item label="Severity" name="severities">
                        <Select allowClear mode="multiple">
                            {Object.values(EVENT_SEVERITIES).map(v =>
                                <Select.Option key={v} value={v}>{v}</Select.Option>
                            )}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item label=" ">
                        <Button icon={<ReloadOutlined/>} onClick={() => this.refresh()}/>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    }

    render() {
        const {pagination, loading, rows, showDelete, deleteBefore} = this.state;

        const extra = <Button onClick={() => this.setState({showDelete: true})} icon={<DeleteOutlined/>}>
            Clear
        </Button>;

        return <PageContent title="System Logs" loading={loading} extra={extra}>
            <Space direction="vertical" size={16} style={{width: '100%'}} className="overflow-x">
                {this.renderFilter()}
                <Table
                    bordered
                    footer={(data) => <span>
                        Showing {data.length} record(s) of total {pagination.total}
                    </span>}
                    rowKey={'id'}
                    columns={this.columns}
                    dataSource={rows}
                    pagination={pagination}
                    onChange={(pagination, filters, sorter) => {
                        this.refresh({pagination, filters, sorter});
                    }}
                />
            </Space>

            <Modal title="Clear Logs" visible={showDelete} onCancel={() => this.setState({showDelete: false})}
                   onOk={this.clear}>
                <Typography.Text>Log older than:</Typography.Text>
                <DatePicker
                    value={deleteBefore} style={{width: '100%', marginTop: 16}} allowClear={false}
                    onChange={value => this.setState({deleteBefore: value})}
                    showToday={false}
                    renderExtraFooter={() => {
                        const style = {cursor: 'pointer'};
                        return <Space>
                            <Tag style={style}
                                 onClick={() => this.setState({deleteBefore: moment().subtract(3, 'months')})}>
                                3 Months
                            </Tag>
                            <Tag style={style}
                                 onClick={() => this.setState({deleteBefore: moment().subtract(6, 'months')})}>
                                6 Months
                            </Tag>
                            <Tag style={style}
                                 onClick={() => this.setState({deleteBefore: moment().subtract(9, 'months')})}>
                                9 Months
                            </Tag>
                        </Space>
                    }}
                />
            </Modal>
        </PageContent>
    }

    componentDidMount() {
        this.refresh();
    }
}

export default connect(null, CommonDispatcher)(Container);
