import React from "react";
import * as moment from "moment-timezone";

import { userService } from "../../services";
import { connect } from "react-redux";
import formatter from "../../helpers/formatter";
import {
  Tag,
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
} from "antd";
import PageContent from "../../components/page-content";
import {
  ReloadOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import CommonDispatcher from "../../store/helpers/commons-dispatcher";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { USER_TYPES } from "../../constants";

class Container extends React.PureComponent {
  static propTypes = {
    types: PropTypes.array,
  };

  static defaultProps = {
    loading: false,
    select_access_control_levels: [],
    onSubmit: (v) => console.warn("action not register", v),
    onUpdateProfilePicture: (v) => console.warn("action not register", v),
  };

  now = moment();

  columns = [
    {
      dataIndex: ["name"],
      sorter: true,
      title: "Name",
    },
    {
      dataIndex: ["username"],
      title: "Username",
      sorter: true,
    },
    {
      dataIndex: "type",
      title: "Type",
      sorter: true,
      render: formatter.toDisplayUserType,
    },
    {
      dataIndex: "email",
      title: "E-mail",
      sorter: true,
    },
    {
      dataIndex: "auth",
      title: "Auth",
      sorter: true,
      align: "center",
      width: 120,
      render: formatter.toDisplayAuthMethod,
    },
    {
      dataIndex: "is_disable",
      title: "Status",
      width: 120,
      align: "center",
      render: (v) => (
        <Tag color={v ? "error" : "success"}>{v ? "Disabled" : "Active"}</Tag>
      ),
    },
    {
      dataIndex: ["id"],
      title: "Actions",
      align: "right",
      render: (id, r) => (
        <Space direction="horizontal" size={4}>
          <Link to={`/admin/users/${r.id}`}>
            <Tooltip title="Edit">
              <Button type="link" icon={<EditOutlined />} title="Edit" />
            </Tooltip>
          </Link>

          {!r.is_disable && (
            <Tooltip title="Disable User">
              <Button
                danger
                type="link"
                icon={<CloseCircleOutlined />}
                title="Disable"
                onClick={() => this.onEnableDisable(r, false)}
              />
            </Tooltip>
          )}

          {r.is_disable && (
            <Tooltip title="Enable User">
              <Button
                type="link"
                icon={<CheckCircleOutlined />}
                title="Enable"
                style={{ color: "green" }}
                onClick={() => this.onEnableDisable(r, true)}
              />
            </Tooltip>
          )}

          <Tooltip title="Delete">
            <Button
              danger
              type="link"
              icon={<DeleteOutlined />}
              title="Delete"
              onClick={() => this.onDelete(r)}
              disabled={[USER_TYPES.SUPER_ADMIN, USER_TYPES.ADMIN].includes(
                r.type
              )}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  state = {
    pagination: { current: 1, pageSize: 10, total: 0 },
    sorter: null,
    rows: [],
    showAdvanceFilter: true,
  };

  filterRef = React.createRef();

  onDelete(r) {
    this.props.confirm(
      `Are you to remove this user? This action cannot be undone.`,
      () => {
        this.setState({ loading: true });
        userService
          .delete(r.id)
          .then((rs) => {
            this.props.notification({ message: "Success", type: "success" });
            this.refresh();
          })
          .catch((err) => {
            this.props.notificationError(err);
            this.setState({ loading: false });
          });
      }
    );
  }

  onEnableDisable(r, enable) {
    this.props.confirm(
      `Are you sure to ${enable ? "enable" : "disable"} this user?`,
      () => {
        this.setState({ loading: true });
        userService
          .update(r.id, { is_disable: !enable })
          .then((rs) => {
            this.props.notification({ message: "Success", type: "success" });
            this.refresh();
          })
          .catch((err) => {
            this.props.notificationError(err);
            this.setState({ loading: false });
          });
      }
    );
  }

  refresh = (params = null) => {
    let { pagination, sorter } = params ? params : {};
    if (!pagination) {
      pagination = this.state.pagination;
    }
    if (!sorter) {
      sorter = this.state.sorter;
    }

    const filter = this.filterRef.current.getFieldsValue();
    const { filter_type, keyword } = filter;
    const query = {};

    if (keyword) {
      query[filter_type] = keyword;
    }

    if (sorter) {
      query.sort = `${sorter.order === "descend" ? "-" : "+"}${
        Array.isArray(sorter.field) ? sorter.field.join(".") : sorter.field
      }`;
    }

    if (this.props.types) {
      query.types = this.props.types;
    }

    this.setState({ loading: true });
    const start = (pagination.current - 1) * pagination.pageSize;
    userService
      .list(start, pagination.pageSize, query)
      .then((rs) => {
        pagination.total = rs.total;
        this.setState({
          rows: rs.items,
          loading: false,
          pagination,
          sorter,
          filter,
        });
      })
      .catch((err) => {
        this.props.notificationError(err);
        this.setState({ loading: false });
      });
  };

  renderFilter = () => {
    const initialValues = {
      filter_type: "name",
      keyword: "",
    };

    return (
      <Form
        layout="vertical"
        initialValues={initialValues}
        onValuesChange={() => this.refresh()}
        ref={this.filterRef}
      >
        <Row>
          <Col span={12}>
            <Row>
              <Col span={6}>
                <Form.Item label="Filter by" name="filter_type">
                  <Select>
                    <Select.Option value="name">Name</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item label=" " name="keyword">
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item label=" ">
                  <Button
                    icon={<ReloadOutlined />}
                    onClick={() => this.refresh()}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    );
  };

  render() {
    const { pagination, loading, rows } = this.state;

    const extra = (
      <Link to={"/admin/users/create"}>
        <Button type="primary">Add</Button>
      </Link>
    );

    return (
      <PageContent title="Users" extra={extra}>
        <Space
          direction="vertical"
          size={16}
          style={{ width: "100%" }}
          className="overflow-x"
        >
          {this.renderFilter()}
          <Table
            bordered
            rowKey={"id"}
            footer={(data) => (
              <span>
                Showing {data.length} record(s) of total {pagination.total}
              </span>
            )}
            columns={this.columns}
            loading={loading}
            dataSource={rows}
            pagination={pagination}
            onChange={(pagination, filters, sorter) => {
              this.refresh({ pagination, filters, sorter });
            }}
          />
        </Space>
      </PageContent>
    );
  }

  componentDidMount() {
    this.refresh();
  }
}

export default connect(null, CommonDispatcher)(Container);
