import React from "react";

import {roomService} from '../../services';
import {connect} from "react-redux";
import {Tag, Button, Col, Form, Input, Row, Select, Space, Table} from "antd";
import PageContent from "../../components/page-content";
import {
    DeleteOutlined,
    EditOutlined,
    ReloadOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined
} from "@ant-design/icons";
import {CommonDispatcher} from '../../store/helpers';
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {ROOM_TYPES} from "../../constants";

class Container extends React.PureComponent {

    static propTypes = {
        type: PropTypes.string,
    };

    static defaultProps = {
        loading: false,
        select_access_control_levels: [],
        onSubmit: (v) => console.warn('action not register', v),
        onUpdateProfilePicture: (v) => console.warn('action not register', v)
    };

    columns = [
        {
            dataIndex: ['name'],
            title: 'Name',
            sorter: true,
        },
        {
            dataIndex: ['devices'],
            title: 'Devices',
            render: (devices) => {
                return devices.map(d => <Tag key={d.id} color={d.is_manage ? 'blue' : 'default'}>{d.name}</Tag>);
            }
        },
        {
            dataIndex: 'is_active',
            title: 'Status',
            width: 120,
            align: 'center',
            sorter: true,
            render: (is_active) => {
                return <Tag color={is_active ? 'success' : 'warning'}>
                    {is_active ? 'Available' : 'Closed'}
                </Tag>
            }
        },
        {
            dataIndex: ['id'],
            title: 'Actions',
            width: 160,
            align: 'right',
            render: (id, r) => {
                const {is_active} = r;

                const openCloseButton = is_active ?
                    <Button onClick={() => this.onMarkActive(id, false)} type="link" color="error"
                            icon={<CloseCircleOutlined/>}
                            title="Mark closed"/> :
                    <Button onClick={() => this.onMarkActive(id, true)} type="link" color="success"
                            icon={<CheckCircleOutlined/>}
                            title="Mark open"/>

                return <Space direction="horizontal" size={2}>
                    <Link to={this.url.edit(r)}>
                        <Button type="link" icon={<EditOutlined/>} title="Edit"/>
                    </Link>

                    {openCloseButton}

                    <Button danger type="link" icon={<DeleteOutlined/>} title="Delete"
                            onClick={() => this.onDelete(r)}
                    />
                </Space>
            }
        },

    ];

    state = {
        pagination: {current: 1, pageSize: 50, total: 0},
        sorter: null,
        rows: []
    };

    filterRef = React.createRef();

    get url() {
        const {type} = this.props;
        return {
            get create() {
                switch (type) {
                    case ROOM_TYPES.DESK:
                        return '/admin/desks/create';
                    default:
                        return '/admin/rooms/create';
                }
            },
            edit(room) {
                switch (room?.type) {
                    case ROOM_TYPES.DESK:
                        return `/admin/desks/${room?.id}`
                    default:
                        return `/admin/rooms/${room?.id}`
                }
            }
        }
    }

    get labels() {
        const {type} = this.props;
        return {
            get name() {
                switch (type) {
                    case ROOM_TYPES.DESK:
                        return 'desk';
                    default:
                        return 'room';
                }
            },
            get title() {
                switch (type) {
                    case ROOM_TYPES.DESK:
                        return 'Desks';
                    default:
                        return 'Rooms';
                }
            }
        }
    }

    onMarkActive(id, is_active) {
        this.props.confirm(`Are you to mark this ${this.labels.name} as ${is_active ? 'open' : 'closed'}?`, () => {
            this.loading = true;
            roomService.update(id, {is_active}).then(rs => {
                this.props.notification({message: 'Success', type: 'success'});
                this.refresh();
            }).catch(err => {
                this.loading = false;
                this.props.notificationError(err);
            });
        });
    }

    onDelete(r) {
        this.props.confirm(`Are you to remove this ${this.labels.name}? This action cannot be undone.`, () => {
            this.loading = true;
            roomService.delete(r.id).then(rs => {
                this.props.notification({message: 'Success', type: 'success'});
                this.refresh();
            }).catch(err => {
                this.loading = false;
                this.props.notificationError(err);
            });
        });
    }

    refresh = (params = null) => {
        let {pagination, sorter} = params ? params : {};
        if (!pagination) {
            pagination = this.state.pagination;
        }
        if (!sorter) {
            sorter = this.state.sorter;
        }

        const filter = this.filterRef.current.getFieldsValue();

        const {filter_type, keyword, status} = filter;

        const query = {};

        if (keyword) {
            query[filter_type] = keyword;
        }
        if (status) {
            if (status === 'available') {
                query.is_available = true;
            } else if (status === 'not_available') {
                query.is_available = false;
            } else if (status === 'closed') {
                query.is_active = false;
            }
        }

        if (sorter && Object.keys(sorter).length > 0) {
            query.sorts = `${sorter.order === 'descend' ? '-' : ''}${Array.isArray(sorter.field) ? sorter.field.join('.') : sorter.field}`;
        }

        if (this.props.type) {
            query.types = this.props.type
        }

        this.setState({loading: true});
        const start = (pagination.current - 1) * pagination.pageSize;
        roomService.list(start, pagination.pageSize, query).then(rs => {
            pagination.total = rs.total;
            this.setState({rows: rs.items, loading: false, pagination, sorter, filter})
        }).catch(err => {
            this.props.notificationError(err);
            this.setState({loading: false});
        });
    };

    renderFilter = () => {

        const initialValues = {
            filter_type: 'name',
            keyword: '',
            status: 'all',
        }

        return <Form layout="vertical" initialValues={initialValues} onValuesChange={() => this.refresh()}
                     ref={this.filterRef}>
            <Row>
                <Col span={12}>
                    <Row gutter={[8, 8]}>
                        <Col span={4}>
                            <Form.Item label="Status" name="status">
                                <Select>
                                    <Select.Option value="all">All</Select.Option>
                                    <Select.Option value="available">Available</Select.Option>
                                    <Select.Option value="not_available">Occupied</Select.Option>
                                    <Select.Option value="closed">Closed</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Filter by" name="filter_type">
                                <Select>
                                    <Select.Option value="name">Name</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            <Form.Item label=" " name="keyword">
                                <Input allowClear/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label=" ">
                                <Button icon={<ReloadOutlined/>} onClick={() => this.refresh()}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    }

    render() {
        const {pagination, loading, rows} = this.state;

        const extra = <Link to={this.url.create}>
            <Button type="primary">Add</Button>
        </Link>

        return <PageContent title={this.labels.title} extra={extra}>
            <Space direction="vertical" size={16} style={{width: '100%'}} className="overflow-x">
                {this.renderFilter()}
                <Table
                    bordered
                    footer={(data) => <span>
                        Showing {data.length} record(s) of total {pagination.total}
                    </span>}
                    rowKey={'id'}
                    columns={this.columns}
                    loading={loading}
                    dataSource={rows}
                    pagination={pagination}
                    onChange={(pagination, filters, sorter) => {
                        this.refresh({pagination, filters, sorter});
                    }}
                />
            </Space>
        </PageContent>
    }

    componentDidMount() {
        this.refresh();
    }
}

export default connect(null, CommonDispatcher)(Container);
