import http from './http';

class SettingService {

    init(params) {
        return http.put(`/api/settings/init`, params);
    }

    initUpload(key, file) {
        const formData = new FormData();
        formData.append('file', file);
        return http.postMultipart(`/api/settings/init/${key}`, formData);
    }

    general() {
        return http.get(`/api/settings/general`);
    }

    // ----

    get(keys) {
        return http.get(`/api/settings`, {keys});
    }

    update(settings) {
        return http.put('/api/settings', settings);
    }

    delete(section) {
        return http.delete(`/api/settings/${section}`);
    }

    upload(key, file, params = {}) {
        const formData = new FormData();
        formData.append('file', file);
        for (const key of ['name']) {
            if (params.hasOwnProperty(key)) {
                formData.append(key, params[key]);
            }
        }
        return http.postMultipart(`/api/settings/${key}`, formData);
    }

    testSendEmail(settings) {
        return http.post(`/api/settings/test/send-email`, settings);
    }

    testBindLdap(settings, preInit = false) {
        return http.post(`/api/settings/test/${preInit ? 'pre-bind-ldap' : 'bind-ldap'}`, settings);
    }
}

export default new SettingService();
