import http from './http';

class RoomService {

    list(offset, limit, query = {}) {
        return http.get(`/api/rooms`, {
            ...query,
            offset,
            limit
        });
    }

    get(id) {
        return http.get(`/api/rooms/${id}`);
    }

    delete(id) {
        return http.delete(`/api/rooms/${id}`);
    }

    create(params) {
        return http.post(`/api/rooms`, params);
    }

    update(id, params) {
        return http.put(`/api/rooms/${id}`, params);
    }

    getSummary(id) {
        return http.get(`/api/rooms/${id}/summary`);
    }

    authorizeGoogle(id, params) {
        return http.post(`/api/rooms/${id}/authorize/google`, params);
    }

    authorizeMicrosoft(id, params) {
        return http.post(`/api/rooms/${id}/authorize/microsoft`, params);
    }

    revokeApi(id) {
        return http.post(`/api/rooms/${id}/revoke-authorize`);
    }

    testApi(id, type) {
        return http.get(`/api/rooms/${id}/test-api`, {type});
    }

    sync(id) {
        return http.get(`/api/rooms/${id}/sync`);
    }

    refreshApiToken(id) {
        return http.get(`/api/rooms/${id}/refresh-api-token`)
    }

    getFacilities(id, offset, limit, query = {}) {
        return http.get(`/api/rooms/${id}/facilities`, {
            ...query,
            offset,
            limit
        });
    }

    addFacility(id, facility_id) {
        return http.post(`/api/rooms/${id}/facilities`, {facility_id});
    }

    updateFacility(id, facility_id, params) {
        return http.put(`/api/rooms/${id}/facilities/${facility_id}`, params);
    }

    removeFacility(room_id, ref_id) {
        return http.delete(`/api/rooms/${room_id}/facilities/${ref_id}`);
    }
}

export default new RoomService();
