import * as moment from 'moment-timezone';
import {
    AUTH_METHODS,
    BOOKING_STATUS,
    DEVICE_TYPES,
    EVENT_TYPE, MAILER_TYPES,
    REPORT_TARGET_TYPES,
    REPORT_TIMESPAN_TYPES,
    REPORT_TYPES,
    ROOM_CALENDER_API_TYPES,
    ROOM_TYPES,
    SYSTEM_BOOKING_MODES,
    USER_TYPES
} from "../constants";

class Formatter {

    toDisplayBookingStatus(v) {
        switch (v) {
            case BOOKING_STATUS.NEW:
                return 'New'
            case BOOKING_STATUS.ONGOING:
                return 'Started'
            case BOOKING_STATUS.ENDED:
                return 'Ended'
            case BOOKING_STATUS.CHECKED_IN:
                return 'Checked In'
            case BOOKING_STATUS.CHECKED_OUT:
                return 'Checked Out'
            case BOOKING_STATUS.CANCELLED:
                return 'Cancelled'
            default:
                return v;
        }
    }

    toDisplayBookingMode(v) {
        switch (v) {
            case SYSTEM_BOOKING_MODES.LOCAL:
                return 'Local'
            case SYSTEM_BOOKING_MODES.GOOGLE:
                return 'Google'
            case SYSTEM_BOOKING_MODES.MICROSOFT:
                return 'Microsoft'
            default:
                return v;

        }
    }

    toDisplayAuthMethod(v) {
        switch (v) {
            case AUTH_METHODS.LOCAL:
                return 'Local'
            case AUTH_METHODS.LDAP:
                return 'LDAP'
            case AUTH_METHODS.GOOGLE:
                return 'Google'
            case AUTH_METHODS.MICROSOFT:
                return 'Microsoft'
            default:
                return v;

        }
    }

    toDisplayDeviceType(v) {
        switch (v) {
            case DEVICE_TYPES.ANDROID_V1:
                return 'Android (V1)'
            case DEVICE_TYPES.ANDROID:
                return 'Android'
            case DEVICE_TYPES.JOAN:
                return 'JOAN'
            case DEVICE_TYPES.WEB_CLIENT:
                return 'GENERIC WEB CLIENT'
            case DEVICE_TYPES.WC_JOAN:
                return 'JOAN WEB CLIENT'
            default:
                return v;
        }
    }

    toDisplayMailerType(v) {
        switch (v) {
            case MAILER_TYPES.MAILGUN:
                return 'Mailgun'
            case MAILER_TYPES.GENERIC:
                return 'Generic'
            default:
                return v;
        }
    }

    toDisplayCalenderApiType(v) {
        switch (v) {
            case ROOM_CALENDER_API_TYPES.GOOGLE:
                return 'Google'
            case ROOM_CALENDER_API_TYPES.MICROSOFT:
                return 'Microsoft'
            default:
                return v;
        }
    }

    toDisplayReportTimeSpan(v) {
        switch (v) {
            case REPORT_TIMESPAN_TYPES.LAST_MONTH:
                return 'Last Month'
            case REPORT_TIMESPAN_TYPES.LAST_WEEK:
                return 'Last Week'
            case REPORT_TIMESPAN_TYPES.YESTERDAY:
                return 'Yesterday'
            case REPORT_TIMESPAN_TYPES.TODAY:
                return 'Today'
            default:
                return v;
        }
    }

    toDisplayReportTargetTimestampType(v) {
        switch (v) {
            case REPORT_TARGET_TYPES.TIMESPAN:
                return 'Time Span'
            case REPORT_TARGET_TYPES.TIMESTAMPS:
                return 'Specific Datetime'
            default:
                return v;
        }
    }

    toDisplayReportType(v) {
        switch (v) {
            case REPORT_TYPES.BOOKINGS:
                return 'Bookings'
            default:
                return v;
        }
    }

    toDisplayNumber(v, minimumFractionDigits = 2) {
        return (v !== null && v !== undefined) ? v.toLocaleString(undefined, {minimumFractionDigits}) : '-';
    }

    minutesToDisplayDuration(minutes) {
        if (!minutes) {
            return '-';
        }

        const m = moment.duration(minutes * 60 * 1000);
        const value = [m.days(), m.hours(), m.minutes()];
        const unit = ['day', 'hour', 'minute'];
        const words = [];
        for (let i = (value.length - 1); i >= 0; i--) {
            if (value[i] !== 0 || words.length > 0) {
                words.push(`${value[i]} ${unit[i]}${value[i] > 0 ? 's' : ''}`);
            }
        }
        return words.reverse().join(' ');
    }


    toDisplayTime(date) {
        return date ? moment(date).format('LT') : '';
    }

    toDisplayDate(date) {
        return date ? moment(date).format('YYYY-MM-DD') : '';
    }

    toDisplayDatetime(date) {
        return date ? moment(date).format('YYYY-MM-DD h:mma') : '';
    }

    toDisplayEntryLogType(type) {
        switch (type) {
            case 'pass_token':
                return 'QR Code';
            default:
                return type;
        }
    }

    toDisplayUserType(type) {
        switch (type) {
            case USER_TYPES.SUPER_ADMIN:
                return 'System Administrator';
            case USER_TYPES.ADMIN:
                return 'Administrator';
            case USER_TYPES.IT:
                return 'IT';
            case USER_TYPES.STAFF:
                return 'Staff';
            default:
                return type;
        }
    }

    toDisplayEventType(type) {
        switch (type) {
            case EVENT_TYPE.ENTRY_ALLOW:
                return 'Entry';
            case EVENT_TYPE.ENTRY_DENIED:
                return 'Entry Denied';
            case EVENT_TYPE.DEVICE_IP_CHANGED:
                return 'Device IP Change';
            case EVENT_TYPE.DEVICE_DISCONNECTED:
                return 'Device Disconnect';
            case EVENT_TYPE.NEW_DEVICE:
                return 'New Device';
            default:
                return type;
        }
    }

    toDisplayRoomType(type) {
        switch (type) {
            case ROOM_TYPES.DESK:
                return 'Desk';
            case ROOM_TYPES.ROOM:
                return 'Room';
            default:
                return type;
        }
    }
}

export default new Formatter();
