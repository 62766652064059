import React from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";

import {roomService, facilityService, integrationService} from '../../services';
import {CommonProps, CommonDispatcher} from "../../store/helpers";
import PageContent from "../../components/page-content";
import RoomForm from '../../screens/room/form';

import {
    Select,
    Tabs,
    Descriptions,
    Row,
    Col,
    List,
    Button,
    Form,
    Input,
    Modal,
    Space,
    Spin,
    Typography,
    Table,
    Tag,
    Badge
} from "antd";
import {
    ExportOutlined,
    ArrowRightOutlined,
    CalendarOutlined,
    CheckCircleOutlined,
    DeleteOutlined,
    CloseCircleOutlined,
} from "@ant-design/icons";
import {ROOM_CALENDER_API_TYPES, ROOM_TYPES} from "../../constants";
import formatter from "../../helpers/formatter";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

class Container extends React.PureComponent {

    static propTypes = {
        type: PropTypes.string,
        isCreate: PropTypes.bool,
        section: PropTypes.string
    };

    static defaultProps = {
        isCreate: false
    };

    id = null;
    isCreate = false;

    roomFacilityForm = React.createRef();

    state = {
        loading: false,
        room: null,
        summary: null,
        authorizing: false,
        on_authorize: false,
        authorize_url: null,

        doing_test: false,
        show_test_results: false,
        test_results: null,

        facilities: [],

        room_facilities: [],
        pagination: {current: 1, pageSize: 10, total: 0},
        sorter: null,

        show_add_facility: false
    };

    columns = [
        {
            dataIndex: ['facility', 'name'],
            title: 'Name',
        },
        {
            dataIndex: 'is_malfunction',
            title: 'Status',
            sorter: true,
            align: 'center',
            width: 120,
            render: (is_malfunction, r) => {

                if (is_malfunction) {
                    return <Tag color="error">Malfunction</Tag>
                }

                if (r.is_reported_malfunction) {
                    return <Tag color="warning">
                        Reported
                    </Tag>
                } else {
                    return <Tag color="success">
                        Working
                    </Tag>
                }
            }
        },
        {
            dataIndex: ['id'],
            title: 'Actions',
            align: 'right',
            width: 120,
            render: (id, r) => {

                const markFixedBtn = (resolve = false) =>
                    <Button key="fix" type="link" icon={<CheckCircleOutlined/>} title="Mark Fixed"
                            onClick={() => this.onMarkFacilityMalfunction(r.room_id, id, false, resolve)}/>;

                const markMalfunctionBtn = (resolve = false) =>
                    <Button key="mark_malfunction" danger type="link" icon={<CloseCircleOutlined/>} title="Mark Fixed"
                            onClick={() => this.onMarkFacilityMalfunction(r.room_id, id, true, resolve)}/>;

                let actions;
                if (r.is_reported_malfunction) {
                    actions = [
                        markFixedBtn(true),
                        markMalfunctionBtn(true)
                    ]
                } else if (r.is_malfunction) {
                    actions = [markFixedBtn()]
                } else {
                    actions = [markMalfunctionBtn()]
                }

                return <Space direction="horizontal" size={2}>
                    {actions}
                    <Button danger type="link" icon={<DeleteOutlined/>} title="Delete"
                            onClick={() => this.onRemoveFacility(r.room?.id, id)}/>
                </Space>
            }
        },
    ];

    get url() {
        const {type} = this.props;
        const {room} = this.state;

        return {
            get back() {
                switch (type) {
                    case ROOM_TYPES.DESK:
                        return `/admin/desks`;
                    default:
                        return `/admin/rooms`;
                }
            },
            get form() {
                switch (type) {
                    case ROOM_TYPES.DESK:
                        return `/admin/desks/${room?.id}/form`;
                    default:
                        return `/admin/rooms/${room?.id}/form`;
                }
            },
            get facility() {
                switch (type) {
                    case ROOM_TYPES.DESK:
                        return `/admin/desks/${room?.id}/facility`;
                    default:
                        return `/admin/rooms/${room?.id}/facility`;
                }
            }
        }
    }

    get labels() {
        const {type, isCreate} = this.props;
        return {
            get name() {
                switch (type) {
                    case ROOM_TYPES.DESK:
                        return 'desk';
                    default:
                        return 'room';
                }
            },
            get title() {
                return `${isCreate ? 'Add' : 'Edit'} ${formatter.toDisplayRoomType(type)}`
            }
        }
    }

    constructor(props) {
        super(props);

        const {id} = props.match.params;
        this.isCreate = !id;
        if (!this.isCreate) {
            this.id = id;
        }
    }

    refresh = (params = null) => {
        if (this.id) {
            const api = [
                roomService.get(this.id),
                roomService.getSummary(this.id)
            ];

            if (this.props.section === 'facility') {
                let {pagination, sorter} = params ? params : {};
                if (!pagination) {
                    pagination = this.state.pagination;
                }
                if (!sorter) {
                    sorter = this.state.sorter;
                }

                const query = {};
                if (sorter && Object.keys(sorter).length > 0) {
                    query.sorts = `${sorter.order === 'descend' ? '-' : ''}${Array.isArray(sorter.field) ? sorter.field.join('.') : sorter.field}`;
                }

                const start = (pagination.current - 1) * pagination.pageSize;
                api.push(roomService.getFacilities(this.id, start, pagination.pageSize, query));
                api.push(facilityService.list(null, null, {is_active: true}));
            }

            this.setState({loading: true});
            Promise.all(api).then(rs => {
                this.setState({
                    room: rs[0],
                    summary: rs[1],
                    room_facilities: rs.length > 2 ? rs[2].items : [],
                    facilities: rs.length > 3 ? rs[3].items : [],
                    loading: false,
                    init_done: true
                })
            }).catch(err => {
                this.props.notificationError(err);
                this.setState({loading: false, init_done: true})
            });

        } else {
            this.setState({init_done: true})
        }
    };

    submit = (values) => {
        this.props.confirm(`Are you sure to ${this.isCreate ? 'submit' : 'update'} ?`, () => {
            this.setState({loading: true});
            (this.isCreate ? roomService.create({
                ...values,
                type: this.props.type
            }) : roomService.update(this.id, values)).then(() => {
                this.props.notification({message: 'Success', type: 'success'});
                if (this.isCreate) {
                    // eslint-disable-next-line no-unused-expressions
                    this.props?.history?.replace(this.url.back);
                } else {
                    this.refresh();
                }
            }).catch(err => {
                this.props.notificationError(err);
                this.setState({loading: false});
            });
        });
    };

    doShowAuthorize = (type) => {
        switch (type) {
            case ROOM_CALENDER_API_TYPES.GOOGLE:
                this.setState({authorizing: true, authorizing_type: type});
                integrationService.getGoogleOAuthUrl().then(rs => {
                    this.setState({authorize_url: rs.url});
                    window.open(rs.url, '_blank');
                }).catch(err => {
                    this.props.notificationError(err);
                    this.setState({authorizing: false});
                });
                break;
            case ROOM_CALENDER_API_TYPES.MICROSOFT:
                const authorize_url = '/microsoft-oauth'
                this.setState({authorizing: true, authorize_url, authorizing_type: type});
                window.open(authorize_url, '_blank');
                break;
            default:
                this.props.notification({message: `Unhandled type ${type}`});
        }
    }

    doAuthorizeApi = (type, id, params) => {
        this.props.confirm(`Are you sure to authorize the calender to this ${this.labels.name} ?`, () => {
            this.setState({on_authorize: true});

            let api;
            switch (type) {
                case ROOM_CALENDER_API_TYPES.GOOGLE:
                    api = roomService.authorizeGoogle(id, params);
                    break;
                case ROOM_CALENDER_API_TYPES.MICROSOFT:
                    api = roomService.authorizeMicrosoft(id, params);
                    break;
                default:
                    this.props.notification({message: `${type} not supporting yet.`})
            }

            if (api) {
                api.then(rs => {
                    this.props.notification({message: 'Success', type: 'success'})
                    this.setState({authorizing: false, on_authorize: false});
                    this.refresh();
                }).catch(err => {
                    this.props.notificationError(err);
                    this.setState({authorizing: false, on_authorize: false});
                });
            } else {
                this.setState({authorizing: false, on_authorize: false});
            }
        })
    };

    doRevokeApi = (id) => {
        this.props.confirm(`Are you sure to revoke the calender access from this ${this.labels.name} ?`, () => {
            this.setState({loading: true});
            roomService.revokeApi(id).then(rs => {
                this.props.notification({message: 'Success', type: 'success'});
                this.refresh();
            }).catch(err => {
                this.props.notificationError(err);
                this.setState({loading: false});
            });
        });
    }

    doTestApi = (id, type = null) => {
        this.props.confirm(`Are you sure to run the API test now ?`, () => {
            this.setState({doing_test: true});
            roomService.testApi(id, type).then(rs => {
                this.setState({
                    test_results: rs,
                    show_test_results: true,
                    doing_test: false
                });
            }).catch(err => {
                this.props.notificationError(err);
                this.setState({doing_test: false});
            });
        })
    };

    updateRoomFacility = (room_id, facility_id, isNew) => {
        this.props.confirm(`Are you sure add new facility for this ${this.labels.name}?`, () => {
            this.setState({loading: true});
            roomService.addFacility(room_id, facility_id).then(rs => {
                this.refresh();
            }).catch(err => {
                this.props.notificationError(err);
                this.setState({loading: false});
            });
        });
    }

    onRemoveFacility = (room_id, ref_id) => {
        this.props.confirm(`Are you sure to remove this facility from this ${this.labels.name}?`, () => {
            this.setState({loading: true});
            roomService.removeFacility(room_id, ref_id).then(rs => {
                this.refresh();
            }).catch(err => {
                this.props.notificationError(err);
                this.setState({loading: false});
            });
        });
    }

    onMarkFacilityMalfunction = (room_id, ref_id, is_malfunction, resolve) => {
        this.props.confirm(`Are you sure to mark the facility as ${is_malfunction ? 'malfunction' : 'fixed'}?`, () => {
            this.setState({loading: true});
            const params = {is_malfunction};
            if (resolve) {
                params.is_reported_malfunction = false;
            }
            roomService.updateFacility(room_id, ref_id, params).then(rs => {
                this.refresh();
            }).catch(err => {
                this.props.notificationError(err);
                this.setState({loading: false});
            });
        });
    };

    doSync = (id) => {
        this.props.confirm(`Are you sure to manual trigger booking sync with authorized calender ?`, () => {
            this.setState({loading: true});
            roomService.sync(id).then(rs => {
                this.props.notification({message: 'Success', type: 'success'});
                this.setState({loading: false});
            }).catch(err => {
                this.props.notificationError(err);
                this.setState({loading: false});
            });
        })
    };

    doRefreshToken = (id) => {
        this.props.confirm(`Are you sure to refresh the token with authorized calender ?`, () => {
            this.setState({loading: true});
            roomService.refreshApiToken(id).then(rs => {
                this.props.notification({message: 'Success', type: 'success'});
                this.setState({loading: false});
            }).catch(err => {
                this.props.notificationError(err);
                this.setState({loading: false});
            });
        })
    };


    renderForm() {
        const {authorizing, authorize_url, authorizing_type, on_authorize} = this.state;
        const {room, init_done, doing_test, test_results, show_test_results} = this.state;

        return init_done && <>
            <RoomForm
                room={room}
                onSubmit={this.submit}
                onAuthorizeApi={(type, params) => this.doAuthorizeApi(type, this.id, params)}
                onTestApi={(type) => this.doTestApi(room?.id, type)}
                onRevokeApi={() => this.doRevokeApi(room?.id)}
                onRefreshApiToken={() => this.doRefreshToken(room?.id)}
                doingTest={doing_test}
                isCreate={this.isCreate}
            />
            <Modal title="Authorize" visible={authorizing} footer={null}
                   onCancel={() => this.setState({authorizing: false})}>
                <Spin spinning={on_authorize}>
                    <Form layout="vertical"
                          onFinish={form => this.doAuthorizeApi(authorizing_type, room.id, form.token)}>
                        <Form.Item label="Token" name="token" rules={[{
                            required: true,
                            message: 'Token is require',
                        }]} extra={
                            <Typography.Link href={authorize_url} target="_blank">
                                Please click here if authorization page not pop showing.&nbsp;<ExportOutlined/>
                            </Typography.Link>
                        }>
                            <Input/>
                        </Form.Item>

                        <Form.Item label=" " style={{textAlign: 'right'}}>
                            <Space>
                                <Button htmlType="button" type="default"
                                        onClick={() => this.setState({authorizing: false})}>
                                    Close
                                </Button>
                                <Button htmlType="submit" type="primary">
                                    Authorize
                                </Button>
                            </Space>
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>

            <Modal title="Calender API Test Result" visible={show_test_results} footer={null}
                   onCancel={() => this.setState({show_test_results: false})} width={520}>

                <Space direction="vertical" size={16} style={{width: '100%'}}>
                    {test_results?.user &&
                    <Descriptions column={1} bordered={true} size="small" title="Calender Owner">
                        <Descriptions.Item label="User">
                            {test_results?.user?.name ? test_results?.user?.name : '-'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Email">
                            {test_results?.user?.email ? test_results?.user?.email : '-'}
                        </Descriptions.Item>
                    </Descriptions>}

                    <Descriptions bordered={true} size="small" title="Upcoming Events">
                        <Descriptions.Item>
                            <List
                                itemLayout="horizontal"
                                dataSource={test_results?.events}
                                renderItem={item => {
                                    return <List.Item>
                                        <List.Item.Meta
                                            avatar={<CalendarOutlined/>}
                                            title={item.title}
                                            description={<>
                                                <Row>
                                                    {item.creator && <>
                                                        <Col span={4}>Creator</Col>
                                                        <Col span={1}>:</Col>
                                                        <Col span={19}>{item.creator?.email}</Col>
                                                    </>}

                                                    <Col span={4}>Organizer</Col>
                                                    <Col span={1}>:</Col>
                                                    <Col span={19}>{item.organizer?.email}</Col>

                                                    <Col span={24}>
                                                        {formatter.toDisplayDatetime(item.start)}
                                                        &nbsp;<ArrowRightOutlined/>&nbsp;
                                                        {formatter.toDisplayDatetime(item.end)}
                                                    </Col>
                                                </Row>
                                            </>}
                                        />
                                    </List.Item>
                                }}
                            />
                        </Descriptions.Item>
                    </Descriptions>
                </Space>
            </Modal>
        </>
    }

    renderFacilities() {
        const {room_facilities, room, pagination, show_add_facility, facilities, loading} = this.state;

        return <>
            <Table
                bordered
                footer={(data) => <span>
                        Showing {data.length} record(s) of total {pagination.total}
                    </span>}
                rowKey={'id'}
                columns={this.columns}
                loading={loading}
                dataSource={room_facilities}
                pagination={pagination}
                onChange={(pagination, filters, sorter) => {
                    this.refresh({pagination, filters, sorter});
                }}
            />

            <Modal title="Add Facility to Room" visible={show_add_facility} onOk={() => {
                if (this.roomFacilityForm.current) {
                    this.roomFacilityForm.current.submit();
                }
            }} okText="Add" onCancel={() => this.setState({show_add_facility: false})}>
                <Form ref={this.roomFacilityForm} layout="vertical" onFinish={form => {
                    this.updateRoomFacility(room.id, form.facility_id, true);
                    this.setState({show_add_facility: false});
                }}>
                    <Form.Item label="Facility" name={'facility_id'} rules={[
                        {required: true, message: 'Please select the facility to add'},
                    ]}>
                        <Select>
                            {facilities && facilities.map(f =>
                                <Select.Option
                                    key={f.id} value={f.id} disabled={!f.is_active}>
                                    {f.name}
                                </Select.Option>)}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    }

    render() {
        const {section} = this.props;
        const {room, summary, loading} = this.state;

        let extra = null;
        if (section === 'form') {
            extra = room && <Button type="primary" onClick={() => this.doSync(room.id)}>
                Sync
            </Button>;
        } else if (section === 'facility') {
            extra = room && <Button type="primary" onClick={() => this.setState({show_add_facility: true})}>
                Add Facility
            </Button>;
        }

        return <PageContent title={this.labels.title} hasBack={true} extra={extra} loading={loading}>
            {this.isCreate && this.renderForm()}

            {!this.isCreate && <Tabs activeKey={section}>
                <Tabs.TabPane
                    tab={<Link replace to={this.url.form}>{formatter.toDisplayRoomType(this.props.type)}</Link>}
                    key="form">
                    {this.renderForm()}
                </Tabs.TabPane>

                <Tabs.TabPane tab={<Link replace to={this.url.facility}>
                    <Badge count={summary?.reported_or_malfunction_facilities} offset={[8, 0]}>
                        Facility
                    </Badge>
                </Link>} key="facility">
                    {this.renderFacilities()}
                </Tabs.TabPane>
            </Tabs>}
        </PageContent>
    }

    componentDidMount() {
        this.refresh();
    }
}

export default connect(CommonProps, CommonDispatcher)(withRouter(Container));
