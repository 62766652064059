import React from "react";
import PropTypes from "prop-types";
import {
    Button,
    Col,
    Form,
    Row,
    Select,
    Tag,
    Tooltip,
    Typography
} from "antd";
import {connect} from "react-redux";
import {CommonProps, CommonDispatcher} from "../../store/helpers";
import * as moment from "moment";
import {ReloadOutlined} from "@ant-design/icons";
import {Calendar, momentLocalizer} from "react-big-calendar";
import formatter from "../../helpers/formatter";

import '../../assets/react-big-calender.css';
import {BOOKING_STATUS, ROOM_TYPES, SETTING_KEYS} from "../../constants";
import ColorHelper from '../../helpers/colors';
import {RoomsSelectors} from "../../components/selectors";

class BookingCalenderScreen extends React.Component {

    static propTypes = {
        type: PropTypes.string,
        bookings: PropTypes.array,
        rooms: PropTypes.array,
        onChange: PropTypes.func,
        onSelect: PropTypes.func,
        onSelectSlot: PropTypes.func
    };

    static defaultProps = {
        onChange: (v) => console.warn('action not register', v),
        onSelect: (v) => console.warn('action not register', v),
        onSelectSlot: (v) => console.warn('action not register', v),
    };

    localizer = momentLocalizer(moment);
    view;

    state = {
        range: {
            start: moment().startOf('month'),
            end: moment().endOf('month'),
        },
        selected_type: null,
        filter: {
            room_type: null,
            rooms_id: []
        }
    }

    componentDidMount() {
        this.refresh();
    }

    handleSubmit = (form) => {
        this.props.onSubmit(form);
    }

    refresh = () => {
        const {filter, range} = this.state;
        this.props.onChange({range, room_type: filter?.room_type, rooms_id: filter?.rooms_id});
    }

    renderFilter = () => {
        const {rooms} = this.props;
        const {filter} = this.state;

        const initialValues = filter;

        const options = !filter?.room_type ? rooms : rooms.filter(r => r.type === filter.room_type);

        return <Form
            layout="vertical"
            initialValues={initialValues}
            onValuesChange={(values) => {
                this.setState({filter: {...filter, ...values}}, () => {
                    this.refresh();
                })
            }}>
            <Row gutter={[8, 8]}>
                <Col span={4}>
                    <Form.Item label="Type" name="room_type">
                        <Select options={[
                            {label: 'All', value: null},
                            ...Object.values(ROOM_TYPES).map(t => {
                                return {
                                    label: formatter.toDisplayRoomType(t),
                                    value: t
                                }
                            })
                        ]}/>
                    </Form.Item>
                </Col>
                <Col span={14}>
                    <Form.Item label=" " name="rooms_id">
                        <RoomsSelectors rooms={options} mode="multiple"/>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item label=" ">
                        <Button icon={<ReloadOutlined/>} onClick={() => this.refresh()}/>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    }

    render() {
        const {app_settings, bookings} = this.props;

        const eventTooltipContent = (event) => {
            const {user, organizer_name, is_cancelled} = event
            const bookedBy = user ? user.name : organizer_name;

            let statusLabel = null;
            if (event.status?.includes(BOOKING_STATUS.CANCELLED)) {
                statusLabel = formatter.toDisplayBookingStatus(BOOKING_STATUS.CANCELLED);
            } else if (event.status?.includes(BOOKING_STATUS.ENDED)) {
                statusLabel = formatter.toDisplayBookingStatus(BOOKING_STATUS.ENDED);
            } else if (event.status?.includes(BOOKING_STATUS.NEW)) {
                statusLabel = formatter.toDisplayBookingStatus(BOOKING_STATUS.NEW);
            } else if (event.status?.includes(BOOKING_STATUS.ONGOING)) {
                if (event?.status?.includes(BOOKING_STATUS.CHECKED_IN)) {
                    statusLabel = formatter.toDisplayBookingStatus(BOOKING_STATUS.CHECKED_IN);
                } else {
                    statusLabel = formatter.toDisplayBookingStatus(BOOKING_STATUS.ONGOING);
                }
            }

            return <Row>
                <Col span={24}>
                    <h4 style={{color: 'white'}}>
                        <Typography.Text style={{color: 'white'}} delete={is_cancelled}>
                            {event?.title}
                        </Typography.Text> {statusLabel && <>&nbsp;({statusLabel})</>}
                    </h4>
                </Col>
                <Col span={24}>
                    <Row>
                        <Col span={8}>
                            Start:
                        </Col>
                        <Col span={16}>
                            {formatter.toDisplayDatetime(event?.start)}
                        </Col>

                        <Col span={8}>
                            End:
                        </Col>
                        <Col span={16}>
                            {formatter.toDisplayDatetime(event?.end)}
                        </Col>

                        <Col span={8}>
                            {formatter.toDisplayRoomType(event?.room?.type)}:
                        </Col>
                        <Col span={16}>
                            {event?.room?.name}
                        </Col>

                        <Col span={8}>
                            Booked by:
                        </Col>
                        <Col span={16}>
                            {bookedBy}
                        </Col>
                    </Row>
                </Col>
            </Row>
        }

        return <Row>
            <Col span={12}>
                {this.renderFilter()}
            </Col>

            <Col span={24}>
                <Calendar
                    selectable
                    onSelectSlot={(params) => {
                        let start = moment(params.start);
                        let end = moment(params.end);

                        const isToday = start.isSameOrAfter(moment().startOf('day')) &&
                            start.isSameOrBefore(moment().endOf('day'));

                        const step = app_settings?.[SETTING_KEYS.BOOKING.TIME_STEP_MINUTES];
                        const standardTimeStep = (datetime) => {
                            if (step) {
                                while (datetime.minutes() % step) {
                                    datetime.add(1, 'minute')
                                }
                            }
                            return datetime;
                        };

                        if (end.isSame(start)) {
                            // selected on full day
                            if (isToday) {
                                start = moment();
                            } else if (start.isAfter(moment().endOf('day')) && moment(start).hour() < 9) {
                                start = moment(start).hour(9);
                            }
                            start = standardTimeStep(start);
                            end = moment(start).add(1, 'hour');
                        } else {
                            start = standardTimeStep(start);
                            end = standardTimeStep(end);
                        }

                        this.props.onSelectSlot({start, end})
                    }}
                    popupOffset={{x: 30, y: 20}}
                    localizer={this.localizer}
                    components={{
                        event: (e) => {
                            const {event} = e;

                            const displayStart = moment(event?.start).format('h:mma');

                            const title = `${displayStart} ${event?.title}`;

                            const tagStyle = {cursor: 'pointer', width: '100%', height: '100%'}
                            if (this.view === 'agenda') {
                                tagStyle.color = 'black';
                            }

                            const color = ColorHelper.getBookingColor(event.status);
                            return <Tooltip title={eventTooltipContent(event)} style={{backgroundColor: null}}>
                                <Tag style={tagStyle} color={color}>
                                    <Typography.Text style={{color: "white"}} delete={event.is_cancelled}>
                                        {title}
                                    </Typography.Text>
                                </Tag>
                            </Tooltip>
                        },
                        agenda: {
                            event: ({event}) => {
                                const {user, organizer_name, title, is_cancelled} = event
                                const bookedBy = user ? user?.name : organizer_name;

                                return <Tooltip title={eventTooltipContent(event)} style={{backgroundColor: null}}>
                                    <Tag style={{color: 'black'}} color="transparent">
                                        <Typography.Text delete={is_cancelled}>
                                            <strong>{title}</strong>&nbsp; - booked by <strong>{bookedBy}</strong>
                                        </Typography.Text>
                                    </Tag>
                                </Tooltip>
                            }
                        }
                    }}
                    events={bookings ? bookings.map(r => {
                        return {
                            id: r.id,
                            title: `${r.title}`,
                            room: r.room,
                            user: r.user,
                            status: r.status,
                            is_cancelled: r.status.includes(BOOKING_STATUS.CANCELLED),
                            cancelled_date: r.cancelled_date,
                            cancelled_by: r.cancelled_user?.name,
                            organizer_name: r.organizer_name,
                            start: moment(r.start).toDate(),
                            end: moment(r.end).toDate(),
                            allDay: false,
                            resource: null,
                        }
                    }) : []}
                    onSelectEvent={this.props.onSelect}
                    startAccessor="start"
                    endAccessor="end"
                    style={{height: 640}}
                    onView={(view) => this.view = view}
                    onRangeChange={(calenderRange, type) => {
                        let range;
                        if (Array.isArray(calenderRange) && calenderRange.length > 0) {
                            if (calenderRange.length === 1) {
                                range = {
                                    start: moment(calenderRange[0]).startOf('day'),
                                    end: moment(calenderRange[0]).endOf('day')
                                }
                            } else {
                                range = {
                                    start: calenderRange[0],
                                    end: calenderRange[calenderRange.length - 1]
                                }
                            }
                        } else if (typeof calenderRange === "object") {
                            range = calenderRange;
                        }

                        if (range) {
                            this.setState({range}, () => {
                                this.refresh();
                            });
                        }
                    }}
                />
            </Col>
        </Row>
    }
}

export default connect(CommonProps, CommonDispatcher)(BookingCalenderScreen);
