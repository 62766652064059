import http from './http';

class UserService {

    list(offset, limit, query = {}) {
        return http.get(`/api/users`, {
            ...query,
            offset,
            limit
        });
    }

    get(id) {
        return http.get(`/api/users/${id}`);
    }

    create(dto) {
        return http.post(`/api/users`, dto);
    }

    update(id, dto) {
        return http.put(`/api/users/${id}`, dto);
    }

    delete(id) {
        return http.delete(`/api/users/${id}`);
    }

}

export default new UserService();
