import React from "react";
import PropTypes from "prop-types";
import {
    Space,
    Switch,
    Button,
    Form,
    Input,
    Select,
    InputNumber,
    Tooltip,
    Row,
    Col, Typography,
} from "antd";

import formatter from "../../helpers/formatter";
import {connect} from "react-redux";
import CommonDispatcher from "../../store/helpers/commons-dispatcher";
import {
    CheckCircleOutlined,
    WindowsOutlined,
    CalendarOutlined,
    CloseCircleOutlined,
    GoogleOutlined,
    DeleteOutlined,
    SwapOutlined, ExportOutlined
} from '@ant-design/icons';
import {ROOM_CALENDER_API_TYPES} from "../../constants";

class RoomFormScreen extends React.PureComponent {

    static propTypes = {
        room: PropTypes.object,
        isCreate: PropTypes.bool,
        onSubmit: PropTypes.func,
        onAuthorizeApi: PropTypes.func,
        onTestApi: PropTypes.func,
        onRevokeApi: PropTypes.func,
        onRefreshApiToken: PropTypes.func,
        doingTest: PropTypes.bool,
    };

    static defaultProps = {
        isCreate: true,
        onSubmit: (v) => console.warn('action not register', v),
        onAuthorizeApi: (v) => console.warn('action not register', v),
        onTestApi: (v) => console.warn('action not register', v),
        onRevokeApi: (v) => console.warn('action not register', v),
        onRefreshApiToken: () => console.warn('action not register'),
    };

    state = {
        selected_calender_api_type: null,
        calender_auth_url: null,
        has_token: false
    }

    formRef = React.createRef();

    constructor(props) {
        super(props);
        if (props.room) {
            this.state.selected_calender_api_type = props.room?.calender_api_type;
            this.state.has_token = props.room?.calender_api_params?.code;
            this.state.calender_auth_url = this.getCalenderAuthUrl(props.room?.calender_api_type);
        }
    }

    handleSubmit = (form) => {
        this.props.onSubmit(form);
    }

    onAuthorize = () => {
        const type = this.formRef.current.getFieldValue('calender_api_type');
        const params = this.formRef.current.getFieldValue('calender_api_params');
        if (type) {
            this.props.onAuthorizeApi(type, params);
        }
    };

    getCalenderAuthUrl = (type) => {
        switch (type) {
            case ROOM_CALENDER_API_TYPES.GOOGLE:
                return '/google-oauth'
            case ROOM_CALENDER_API_TYPES.MICROSOFT:
                return '/microsoft-oauth'
            default:
                return null;
        }
    }

    render() {
        const {room, isCreate, doingTest, authorizing, onTestApi, onRevokeApi} = this.props;
        const {selected_calender_api_type, calender_auth_url} = this.state;

        const initialValues = {
            name: room?.name,
            description: room?.description,
            capacity: room?.capacity,
            calender_api_type: room?.calender_api_type,
            is_sync_calender_api: !!room?.is_sync_calender_api,
            calender_api_params: room?.calender_api_params,
        }

        if (initialValues.calender_api_params?.code) {
            delete initialValues.calender_api_params.code;
        }

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 3},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 12},
            },
            labelAlign: 'left',
            colon: false,
            onValuesChange: async (v) => {

                if (v.hasOwnProperty('calender_api_params')) {
                    const calender_api_params = v.calender_api_params;
                    if (calender_api_params.hasOwnProperty('token')) {
                        this.setState({has_token: !!calender_api_params.token});
                    }
                }

                if (v.hasOwnProperty('calender_api_type')) {
                    let calender_auth_url = this.getCalenderAuthUrl(v.calender_api_type);

                    if (!v.calender_api_type) {
                        this.formRef.current.setFieldsValue({is_sync_calender_api: false});
                    }
                    this.setState({selected_calender_api_type: v.calender_api_type, calender_auth_url});
                }
            }
        };


        const innerFormItem = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 24},
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 24},
            },
        }

        const isApiTokenGranted = room && room.is_api_granted;

        const roomCalenderApiIcon = v => {
            switch (v) {
                case ROOM_CALENDER_API_TYPES.GOOGLE:
                    return <GoogleOutlined/>
                case ROOM_CALENDER_API_TYPES.MICROSOFT:
                    return <WindowsOutlined/>
                default:
                    return <CalendarOutlined/>;
            }
        };

        return <Form {...formItemLayout} initialValues={initialValues} onFinish={this.handleSubmit} ref={this.formRef}>

            <Form.Item label="Name" name="name" rules={[{
                required: true,
                message: 'Name is require',
            }]}>
                <Input/>
            </Form.Item>
            <Form.Item label="Description" name="description">
                <Input/>
            </Form.Item>

            <Form.Item label="Capacity" name="capacity">
                <InputNumber/>
            </Form.Item>

            {!isCreate && <>
                <Form.Item label="Calender Type" name="calender_api_type">
                    <Select allowClear={true}>
                        {Object.values(ROOM_CALENDER_API_TYPES).map(value =>
                            <Select.Option key={value} value={value}>
                                {roomCalenderApiIcon(value)}&nbsp;
                                {formatter.toDisplayCalenderApiType(value)}
                            </Select.Option>
                        )}
                    </Select>
                </Form.Item>

                <Form.Item label="Calender API">
                    <Row>
                        <Col span={24}>
                            {/*<Form.Item {...innerFormItem} label="Username" name={['calender_api_params', 'username']}>
                                <Input/>
                            </Form.Item>
                            <Form.Item {...innerFormItem} label="Password" name={['calender_api_params', 'password']}>
                                <Input.Password/>
                            </Form.Item>*/}
                            {[
                                ROOM_CALENDER_API_TYPES.MICROSOFT,
                                ROOM_CALENDER_API_TYPES.GOOGLE
                            ].includes(selected_calender_api_type) &&
                            <Form.Item
                                {...innerFormItem} label="Authorization Code"
                                name={['calender_api_params', 'code']}
                                extra={
                                    calender_auth_url && <Typography.Link href={calender_auth_url} target="_blank">
                                        Click here to start the calender authorization&nbsp;
                                        <ExportOutlined/>
                                    </Typography.Link>
                                }
                            >
                                <Input.Password/>
                            </Form.Item>}
                        </Col>

                        <Col>
                            <Row gutter={[8, 8]}>
                                <Col>
                                    <Input.Group compact>
                                        <Button loading={authorizing} htmlType="button"
                                                onClick={() => this.onAuthorize()}>
                                            Authorize
                                            {isApiTokenGranted ?
                                                <CheckCircleOutlined style={{color: 'green'}}/> :
                                                <CloseCircleOutlined style={{color: 'red'}}/>
                                            }
                                        </Button>

                                        {isApiTokenGranted && <>
                                            <Tooltip title="Revoke">
                                                <Button icon={<DeleteOutlined/>} htmlType="button" type="default"
                                                        onClick={onRevokeApi}/>
                                            </Tooltip>
                                        </>}
                                    </Input.Group>
                                </Col>
                                <Col span={1}>
                                    {isApiTokenGranted && <Space>
                                        <Tooltip title="Test API">
                                            <Button icon={<SwapOutlined/>} htmlType="button" type="default"
                                                    onClick={() => {
                                                        const type = this.formRef.current ? this.formRef.current.getFieldValue('calender_api_type') : null;
                                                        onTestApi(type);
                                                    }} loading={doingTest}/>
                                        </Tooltip>
                                    </Space>}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item label="Enable Calender sync" name="is_sync_calender_api" valuePropName="checked">
                    <Switch/>
                </Form.Item>
            </>}

            <Form.Item label=" ">
                <Button htmlType="submit" type="primary">
                    {room ? 'Update' : 'Submit'}
                </Button>
            </Form.Item>
        </Form>
    }
}

export default connect(null, CommonDispatcher)(RoomFormScreen);
