import http from './http';

class DeviceService {

    list(offset = null, limit = null, query = {}) {
        return http.get('/api/devices', {offset, limit, ...query});
    }

    get(id) {
        return http.get(`/api/devices/${id}`);
    }

    update(id, params) {
        return http.put(`/api/devices/${id}`, params);
    }

    create(params) {
        return http.post(`/api/devices`, params);
    }

    delete(id) {
        return http.delete(`/api/devices/${id}`);
    }

    command(id, params) {
        return http.post(`/api/devices/${id}/command`, params);
    }
}

export default new DeviceService();
