import React from "react";
import {Card, Upload, Button, Form, Input, Space, Switch} from "antd";

import {SETTING_KEYS} from "../../constants";
import PropTypes from "prop-types";
import fileHelper from "../../helpers/file";
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import {CommonDispatcher, CommonProps} from "../../store/helpers";
import {connect} from "react-redux";
import {formLayoutColInput} from "./config";
import {Acl} from "../../helpers/acl";
import {ACL_ACTIONS} from "../../constants/acl";
import PermissionDeniedScreen from "../status/permission-denied.screen";

export const USING_KEYS = [
    SETTING_KEYS.DEVICE.APP.APK_LINK,
    SETTING_KEYS.DEVICE.APP.APK_VERSION,
    SETTING_KEYS.DEVICE.COMPANY_NAME,
    SETTING_KEYS.DEVICE.LOGO,

    SETTING_KEYS.AUTH.DASHBOARD_PANEL.TOKEN,

    SETTING_KEYS.DEVICE.NTP_SERVER1,

    SETTING_KEYS.DEVICE.ACTIONS_REQUIRED_PIN.BOOKING.CHECKIN,
    SETTING_KEYS.DEVICE.ACTIONS_REQUIRED_PIN.BOOKING.EXTEND,
    SETTING_KEYS.DEVICE.ACTIONS_REQUIRED_PIN.BOOKING.CHECKOUT,
    SETTING_KEYS.DEVICE.ACTIONS_REQUIRED_PIN.FACILITIES.REPORT,
];

class DeviceForm extends React.PureComponent {
    static propTypes = {
        settings: PropTypes.object,
        onSubmit: PropTypes.func,
        onSetLogo: PropTypes.func,
    };

    static defaultProps = {
        onSubmit: (v) => console.warn("action not register", v),
        onSetLogo: (v) => console.warn("action not register", v),
    };

    formRef = React.createRef();

    state = {
        logo: null,
    };

    constructor(props, context) {
        super(props, context);

        if (props.settings?.[SETTING_KEYS.DEVICE.LOGO]) {
            this.state.logo = props.settings?.[SETTING_KEYS.DEVICE.LOGO];
        }
    }

    onFinish = (form) => {
        this.props.onSubmit(form);
    };

    render() {
        const {logo, uploading} = this.state;
        const {auth, settings} = this.props;

        const allowEditDevice = Acl(auth).isAllowed(ACL_ACTIONS.SETTINGS.UPDATE_DEVICE);

        if (!allowEditDevice) {
            return <PermissionDeniedScreen/>
        }

        const formItemLayout = {
            ...formLayoutColInput,
        };

        const initialValues = {};
        USING_KEYS.forEach((k) => {
            initialValues[k] = settings?.[k];
        });

        const uploadButton = (
            <div>
                {uploading ? <LoadingOutlined/> : <PlusOutlined/>}
                <div style={{marginTop: 8}}>Upload</div>
            </div>
        );

        return (
            <>
                <Form
                    {...formItemLayout}
                    ref={this.formRef}
                    initialValues={initialValues}
                    onFinish={this.onFinish}
                >
                    <Space style={{width: "100%"}} direction="vertical" size={16}>
                        <Card title="General" size="small">
                            <Form.Item
                                label="Display Company Name"
                                name={SETTING_KEYS.DEVICE.COMPANY_NAME}
                            >
                                <Input/>
                            </Form.Item>

                            <Form.Item label="Logo">
                                <Upload
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    showUploadList={false}
                                    beforeUpload={() => false}
                                    onChange={(info) => {
                                        const {file} = info;
                                        const isJpgOrPng =
                                            file.type === "image/jpeg" || file.type === "image/png";
                                        if (!isJpgOrPng) {
                                            this.props.notification({
                                                message: "You can only upload JPG/PNG file!",
                                                type: "warning",
                                            });
                                        }
                                        const isLt2M = file.size / 1024 / 1024 < 2;
                                        if (!isLt2M) {
                                            this.props.notification({
                                                message: "Image must smaller than 2MB!",
                                                type: "warning",
                                            });
                                        }

                                        if (isLt2M && isJpgOrPng) {
                                            fileHelper.fileToBase64(info.file).then((base64) => {
                                                this.setState({logo: base64});
                                                this.props.confirm(
                                                    `Are you sure to upload the device logo?`,
                                                    () => {
                                                        this.props.onSubmit(
                                                            {
                                                                [SETTING_KEYS.DEVICE.LOGO]: base64,
                                                            },
                                                            false
                                                        );
                                                    },
                                                    () => {
                                                        this.setState({
                                                            logo: settings?.[SETTING_KEYS.DEVICE.LOGO],
                                                        });
                                                    }
                                                );
                                            });
                                        }
                                        return false;
                                    }}
                                >
                                    {logo ? (
                                        <img
                                            src={`${logo}`}
                                            alt="avatar"
                                            style={{width: "100%", padding: 8}}
                                        />
                                    ) : (
                                        uploadButton
                                    )}
                                </Upload>
                            </Form.Item>

                            <Form.Item
                                label="NTP Server"
                                name={SETTING_KEYS.DEVICE.NTP_SERVER1}
                            >
                                <Input allowClear/>
                            </Form.Item>
                        </Card>

                        <Card title="APK" size="small">
                            <Form.Item
                                label="App Version"
                                name={SETTING_KEYS.DEVICE.APP.APK_VERSION}
                            >
                                <Input allowClear/>
                            </Form.Item>
                            <Form.Item
                                label="App APK URL"
                                name={SETTING_KEYS.DEVICE.APP.APK_LINK}
                            >
                                <Input allowClear/>
                            </Form.Item>
                        </Card>

                        <Card title="Dashboard" size="small">
                            <Form.Item
                                label="Authorization Token"
                                name={SETTING_KEYS.AUTH.DASHBOARD_PANEL.TOKEN}
                            >
                                <Input.Password allowClear/>
                            </Form.Item>
                        </Card>

                        <Card title="Room" size="small">
                            <Card title="Required User Pin on" bordered={false} size="small">
                                <Form.Item
                                    label="Report Facility"
                                    name={
                                        SETTING_KEYS.DEVICE.ACTIONS_REQUIRED_PIN.FACILITIES.REPORT
                                    }
                                    valuePropName="checked"
                                >
                                    <Switch/>
                                </Form.Item>
                            </Card>
                        </Card>

                        <Card title="Booking" size="small">
                            <Card title="Required User Pin on" bordered={false} size="small">
                                <Form.Item
                                    label="Check In"
                                    name={
                                        SETTING_KEYS.DEVICE.ACTIONS_REQUIRED_PIN.BOOKING.CHECKIN
                                    }
                                    valuePropName="checked"
                                >
                                    <Switch/>
                                </Form.Item>
                                <Form.Item
                                    label="Extend"
                                    name={SETTING_KEYS.DEVICE.ACTIONS_REQUIRED_PIN.BOOKING.EXTEND}
                                    valuePropName="checked"
                                >
                                    <Switch/>
                                </Form.Item>
                                <Form.Item
                                    label="Check Out"
                                    name={
                                        SETTING_KEYS.DEVICE.ACTIONS_REQUIRED_PIN.BOOKING.CHECKOUT
                                    }
                                    valuePropName="checked"
                                >
                                    <Switch/>
                                </Form.Item>
                            </Card>
                        </Card>

                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Save
                            </Button>
                        </Form.Item>
                    </Space>
                </Form>
            </>
        );
    }
}

export default connect(CommonProps, CommonDispatcher)(DeviceForm);
