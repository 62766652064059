import React from "react";
import { connect } from "react-redux";

import { settingService } from "../../services";
import { CommonProps, CommonDispatcher } from "../../store/helpers";
import {
  Row,
  Col,
  Form,
  Card,
  Input,
  Select,
  Button,
  Space,
  Typography,
  Upload,
  Tooltip,
} from "antd";
import LOGO from "../../assets/images/logo-long.png";
import {
  APP,
  AUTH_METHODS,
  SETTING_KEYS,
  SYSTEM_BOOKING_MODES,
} from "../../constants";
import Formatter from "../../helpers/formatter";
import AuthLdapForm from "../../screens/settings/forms/auth-ldap-form";
import { QuestionCircleOutlined, UploadOutlined } from "@ant-design/icons";
import fileHelper from "../../helpers/file";

class InitContainer extends React.PureComponent {
  state = {};

  formRef = React.createRef();

  doTestLdap = () => {
    this.props.confirm(`Do you want to test LDAP connectivity?`, () => {
      this.setState({ loading: true });
      settingService
        .testBindLdap(this.formRef.current.getFieldsValue(), true)
        .then((rs) => {
          this.props.notification({ message: "Success", type: "success" });
          this.setState({ ldap_result: rs, loading: false });
        })
        .catch((err) => {
          this.props.notificationError(err);
          this.setState({ loading: false });
        });
    });
  };

  submit = (values) => {
    this.props.confirm(`Are you sure to submit ?`, () => {
      this.setState({ loading: true });
      settingService
        .init(values)
        .then(() => {
          this.props.notification({ message: "Success", type: "success" });
          window.location.href = "/";
        })
        .catch((err) => {
          this.props.notificationError(err);
          this.setState({ loading: false });
        });
    });
  };

  renderForm() {
    const initialValues = {
      [SETTING_KEYS.AUTH.METHOD]: AUTH_METHODS.LOCAL,
      [SETTING_KEYS.BOOKING.MODE]: SYSTEM_BOOKING_MODES.LOCAL,
    };

    const allowedAuthMethods = [
      AUTH_METHODS.LOCAL,
      AUTH_METHODS.MICROSOFT,
      AUTH_METHODS.GOOGLE,
      AUTH_METHODS.LDAP,
    ];

    return (
      <Form
        ref={this.formRef}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        layout="horizontal"
        initialValues={initialValues}
        onValuesChange={(v) => {
          if (
            v.hasOwnProperty(SETTING_KEYS.BOOKING.MODE) ||
            v.hasOwnProperty(SETTING_KEYS.AUTH.METHOD)
          ) {
            this.setState({ ...this.state, ...v });
          }
        }}
        onFinish={this.submit}
        colon={false}
      >
        <Card type="inner" title="General" size="small" className="mb20">
          <Form.Item
            label="Company Name"
            name={SETTING_KEYS.SYSTEM.COMPANY.NAME}
            rules={[
              {
                required: true,
                message: "Company name is required",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Card>

        <Card type="inner" title="Authentication" size="small" className="mb20">
          <Form.Item
            label="Method"
            name={SETTING_KEYS.AUTH.METHOD}
            rules={[
              {
                required: true,
                message: "Please select the authentication method",
              },
            ]}
          >
            <Select>
              {allowedAuthMethods.map((v) => {
                return (
                  <Select.Option key={v} value={v}>
                    {Formatter.toDisplayAuthMethod(v)}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          {this.state[SETTING_KEYS.AUTH.METHOD] === AUTH_METHODS.LDAP && (
            <AuthLdapForm onTest={this.doTestLdap} />
          )}

          {this.state[SETTING_KEYS.AUTH.METHOD] === AUTH_METHODS.GOOGLE && (
            <>
              <Form.Item
                label="Client ID"
                name={SETTING_KEYS.BOOKING.CALENDER.GOOGLE.CLIENT_ID}
              >
                <Input />
              </Form.Item>
            </>
          )}

          {this.state[SETTING_KEYS.AUTH.METHOD] === AUTH_METHODS.MICROSOFT && (
            <>
              <Form.Item
                label="Client ID"
                name={SETTING_KEYS.BOOKING.CALENDER.MICROSOFT.CLIENT_ID}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Tenant ID"
                name={SETTING_KEYS.BOOKING.CALENDER.MICROSOFT.TENANT_ID}
              >
                <Input />
              </Form.Item>
            </>
          )}
        </Card>

        <Card type="inner" title="Booking" size="small" className="mb20">
          <Form.Item
            label="Mode"
            name={SETTING_KEYS.BOOKING.MODE}
            rules={[
              {
                required: true,
                message: "Please select the booking mode",
              },
            ]}
          >
            <Select>
              {Object.values(SYSTEM_BOOKING_MODES).map((v) => (
                <Select.Option key={v} value={v}>
                  {Formatter.toDisplayBookingMode(v)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          {this.state[SETTING_KEYS.BOOKING.MODE] ===
            SYSTEM_BOOKING_MODES.MICROSOFT && (
            <>
              <Form.Item
                label="Client ID"
                name={SETTING_KEYS.BOOKING.CALENDER.MICROSOFT.CLIENT_ID}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Tenant ID"
                name={SETTING_KEYS.BOOKING.CALENDER.MICROSOFT.TENANT_ID}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Client Secret"
                name={SETTING_KEYS.BOOKING.CALENDER.MICROSOFT.CLIENT_SECRET}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Redirect URI"
                name={SETTING_KEYS.BOOKING.CALENDER.MICROSOFT.REDIRECT_URI}
              >
                <Input />
              </Form.Item>
            </>
          )}

          {this.state[SETTING_KEYS.BOOKING.MODE] ===
            SYSTEM_BOOKING_MODES.GOOGLE && (
            <>
              <Form.Item
                label={
                  <>
                    Authentication File&nbsp;
                    <Tooltip title="You can download the credential JSON from your credential portal.">
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </>
                }
                name={SETTING_KEYS.BOOKING.CALENDER.GOOGLE.CREDENTIAL_FILE}
                valuePropName="file"
              >
                <Upload
                  listType="text"
                  onRemove={() => {
                    console.log("remove");
                    this.setState({
                      [SETTING_KEYS.BOOKING.CALENDER.GOOGLE
                        .CREDENTIAL_FILE]: undefined,
                    });
                  }}
                  beforeUpload={(file, list) => {
                    fileHelper.fileToBase64(file).then((base64) => {
                      this.formRef.current.setFieldsValue({
                        [SETTING_KEYS.BOOKING.CALENDER.GOOGLE
                          .CREDENTIAL_FILE]: base64,
                      });
                      this.setState({
                        [SETTING_KEYS.BOOKING.CALENDER.GOOGLE
                          .CREDENTIAL_FILE]: base64,
                      });
                    });
                    return false;
                  }}
                >
                  <Button
                    icon={<UploadOutlined />}
                    disabled={
                      this.state[
                        SETTING_KEYS.BOOKING.CALENDER.GOOGLE.CREDENTIAL_FILE
                      ]
                    }
                  >
                    Select file to upload
                  </Button>
                </Upload>
              </Form.Item>
            </>
          )}
        </Card>
        <div className="text-center">
          <Button type="primary" htmlType="submit" style={{ width: "30%" }}>
            Submit
          </Button>
        </div>
      </Form>
    );
  }

  render() {
    return (
      <Row justify="center" align="middle" style={{ height: "100vh" }}>
        <Col span={12}>
          <Row justify="center" gutter={[16, 16]}>
            <Col span={24}>
              <Space
                size={2}
                style={{ width: "100%", textAlign: "center" }}
                direction="vertical"
              >
                <img
                  alt="Logo"
                  style={{ width: "50%", padding: 16 }}
                  src={LOGO}
                />
                <Typography.Title level={5}>{APP.NAME}</Typography.Title>
                <Typography.Text>SYSTEM SETUP</Typography.Text>
              </Space>
            </Col>

            <Col md={24}>
              <Card bordered={false}>{this.renderForm()}</Card>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default connect(CommonProps, CommonDispatcher)(InitContainer);
