import React from "react";
import {Router, Route, Switch, Redirect} from "react-router-dom";
import history from './history';

import AuthLayout from '../components/layout/auth';
import LoginContainer from '../containers/auth/login';
import ForgetPasswordContainer from '../containers/auth/forget-password';
import FirstTimeLoginContainer from '../containers/auth/first-time-login';

import ProfileContainer from '../containers/auth/profile';

import AdminLayout from '../components/layout/admin';

import BookingList from '../containers/bookings/list';
import DeviceList from '../containers/devices/list';
import DeviceGet from '../containers/devices/get';
import RoomList from '../containers/rooms/list';
import RoomGet from '../containers/rooms/get';
import FacilityList from '../containers/facilities/list';
import FacilityGet from '../containers/facilities/get';
import SettingTabs from '../containers/settings/tabs';
import LogList from '../containers/logs/list';
import UserList from '../containers/users/list';
import UserGet from '../containers/users/get';
import AdsList from '../containers/ads/list';
import AdsGet from '../containers/ads/get';

import ReportList from '../containers/reports/list';
import ReportGet from '../containers/reports/get';
import ReportGraph from '../containers/reports/graph';

import MicrosoftCalenderOauth from '../containers/oauth/microsoft-calender';

import Error404 from '../screens/errors/404';
import {ROOM_TYPES} from "../constants";

// import TestCalender from '../screens/testing/calender';
// import TestDragAndDrop from '../screens/testing/dnd';

export default class Routes extends React.PureComponent {
    render() {
        return <Router history={history}>
            <Switch>
                {/*Testing*/}
                {/*{<Route path="/test" component={AdminLayout(TestDragAndDrop)}/>}*/}

                {<Redirect exact from="/" to="/login"/>}
                <Route path="/login" component={AuthLayout(LoginContainer)}/>
                <Route path="/forgot-password" component={AuthLayout(ForgetPasswordContainer)}/>
                <Route path="/first-time-login" component={AuthLayout(FirstTimeLoginContainer)}/>

                <Route path="/profile" component={AdminLayout(ProfileContainer)}/>

                <Route path="/microsoft-oauth" component={MicrosoftCalenderOauth}/>

                {<Redirect exact from="/admin" to="/admin/bookings"/>}
                <Route path="/admin/bookings" component={AdminLayout(BookingList)}/>

                {<Redirect exact from="/admin/devices" to="/admin/devices/managed"/>}
                <Route path="/admin/devices/managed" component={AdminLayout(DeviceList, {section: 'managed'})}/>
                <Route path="/admin/devices/not-managed" component={AdminLayout(DeviceList, {section: 'not-managed'})}/>
                <Route path="/admin/devices/create" component={AdminLayout(DeviceGet, {isCreate: true})}/>
                <Route path="/admin/devices/:id" component={AdminLayout(DeviceGet)}/>

                <Route path="/admin/rooms/create"
                       component={AdminLayout(RoomGet, {section: 'form', type: ROOM_TYPES.ROOM, isCreate: true})}/>
                {<Redirect exact from="/admin/rooms/:id" to="/admin/rooms/:id/form"/>}
                <Route path="/admin/rooms/:id/form"
                       component={AdminLayout(RoomGet, {section: 'form', type: ROOM_TYPES.ROOM})}/>
                <Route path="/admin/rooms/:id/facility"
                       component={AdminLayout(RoomGet, {section: 'facility', type: ROOM_TYPES.ROOM})}/>
                <Route path="/admin/rooms" component={AdminLayout(RoomList, {type: ROOM_TYPES.ROOM})}/>

                <Route path="/admin/desks/create"
                       component={AdminLayout(RoomGet, {section: 'form', type: ROOM_TYPES.DESK, isCreate: true})}/>
                {<Redirect exact from="/admin/desks/:id" to="/admin/desks/:id/form"/>}
                <Route path="/admin/desks/:id/form"
                       component={AdminLayout(RoomGet, {section: 'form', type: ROOM_TYPES.DESK})}/>
                <Route path="/admin/desks/:id/facility"
                       component={AdminLayout(RoomGet, {section: 'facility', type: ROOM_TYPES.DESK})}/>
                <Route path="/admin/desks" component={AdminLayout(RoomList, {type: ROOM_TYPES.DESK})}/>


                <Route path="/admin/facilities/create" component={AdminLayout(FacilityGet, {isCreate: true})}/>
                <Route path="/admin/facilities/:id" component={AdminLayout(FacilityGet)}/>
                <Route path="/admin/facilities" component={AdminLayout(FacilityList)}/>

                {<Redirect exact from="/admin/reports" to="/admin/reports/graph"/>}

                <Route path="/admin/reports/graph" component={AdminLayout(ReportGraph)}/>

                <Route path="/admin/reports/view/create" component={AdminLayout(ReportGet, {isCreate: true})}/>
                <Route path="/admin/reports/view/:id" component={AdminLayout(ReportGet)}/>
                <Route path="/admin/reports/view" component={AdminLayout(ReportList)}/>

                <Route path="/admin/users/create" component={AdminLayout(UserGet, {isCreate: true})}/>
                <Route path="/admin/users/:id" component={AdminLayout(UserGet)}/>
                <Route path="/admin/users" component={AdminLayout(UserList)}/>

                <Route path="/admin/ads/create" component={AdminLayout(AdsGet, {isCreate: true})}/>
                <Route path="/admin/ads/:id" component={AdminLayout(AdsGet)}/>
                <Route path="/admin/ads" component={AdminLayout(AdsList)}/>

                {<Redirect exact from="/admin/system/settings" to="/admin/system/settings/system"/>}
                {['system', 'device', 'booking', 'notification'].map(section => {
                    return <Route
                        key={section}
                        path={`/admin/system/settings/${section}`}
                        component={AdminLayout(SettingTabs, {section})}/>
                })}

                <Route path="/admin/system/logs" component={AdminLayout(LogList)}/>

                <Route path="/error/404" component={Error404}/>

                <Route component={Error404}/>
            </Switch>
        </Router>;
    }
}
