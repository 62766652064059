import React from "react";

import {deviceService, roomService} from '../../services';
import {connect} from "react-redux";
import {Modal, Tag, Button, Col, Form, Input, Row, Select, Space, Table, Tabs, Drawer} from "antd";
import PageContent from "../../components/page-content";
import {
    EditOutlined,
    FolderAddOutlined,
    ReloadOutlined,
    CloseCircleOutlined,
    InfoCircleOutlined,
    DeleteOutlined,
} from "@ant-design/icons";
import CommonDispatcher from '../../store/helpers/commons-dispatcher';
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

import DeviceStatusScreen from "../../screens/device/status";
import formatter from "../../helpers/formatter";
import {RoomsSelectors} from "../../components/selectors";

class Container extends React.PureComponent {

    static propTypes = {
        types: PropTypes.array,
    };

    static defaultProps = {
        loading: false,
        select_access_control_levels: [],
        onSubmit: (v) => console.warn('action not register', v),
        onUpdateProfilePicture: (v) => console.warn('action not register', v)
    };

    columns = [
        {
            dataIndex: ['name'],
            sorter: true,
            title: 'Name'
        },
        {
            dataIndex: ['hostname'],
            sorter: true,
            title: 'Hostname'
        },
        {
            dataIndex: ['ip'],
            sorter: true,
            title: 'IP'
        },
        {
            dataIndex: 'serial',
            title: 'Serial No.',
            sorter: true,
        },
        {
            dataIndex: 'firmware',
            title: 'Firmware',
            sorter: true,
        },
        {
            dataIndex: ['type'],
            sorter: true,
            title: 'Type',
            render: formatter.toDisplayDeviceType
        },
        {
            dataIndex: 'room',
            title: 'Room/Desk',
            render: (r) => r?.name
        },
        {
            dataIndex: 'is_up',
            title: 'Status',
            sorter: true,
            render: (v, r) => <Tag color={!r.is_up ? 'red' : 'green'}>
                {r.is_up ? 'Up' : 'Down'}
            </Tag>
        },
        {
            dataIndex: ['id'],
            title: 'Actions',
            align: 'right',
            render: (id, r) => {
                const infoButton = <Button type="link" icon={<InfoCircleOutlined/>} title="Info"
                                           onClick={() => this.setState({showing: r})}
                />

                if (r.is_manage) {
                    return <Space size={2}>
                        {infoButton}
                        <Link to={`/admin/devices/${r.id}`}>
                            <Button type="link" icon={<EditOutlined/>} title="Edit"/>
                        </Link>

                        <Button danger type="link" icon={<CloseCircleOutlined/>} title="Release"
                                onClick={() => this.doManageOrRelease(r, false)}/>

                        {/*<Button type="link" icon={<MacCommandOutlined/>} title="Send Command"*/}
                        {/*        onClick={() => this.setState({sendingCommand: r})}/>*/}

                    </Space>
                } else {
                    return <Space size={2}>
                        {infoButton}
                        <Button type="link" icon={<FolderAddOutlined/>} title="Add"
                                onClick={() => this.doManageOrRelease(r, true)}/>

                        <Button danger type="link" icon={<DeleteOutlined/>} title="Delete"
                                onClick={() => this.doDelete(id)}/>
                    </Space>
                }

            }
        },

    ];

    state = {
        pagination: {current: 1, pageSize: 50, total: 0},
        sorter: null,
        rows: [],

        editing: null,
        rooms: [],
        sendingCommand: null
    };

    filterRef = React.createRef();

    doDelete(id) {
        this.props.confirm(`Are you sure to delete this device?`, () => {
            this.setState({loading: true});
            deviceService.delete(id).then(rs => {
                this.props.notification({message: 'Success', type: 'success'});
                this.refresh();
            }).catch(err => {
                this.props.notificationError(err);
                this.setState({loading: false});
            })
        });
    }

    doManageOrRelease(r, is_manage) {
        let message;
        if (is_manage) {
            message = `Are you sure to add this device into managed list ?`;
        } else {
            message = `Are you sure to remove this device from managed list ? Un-manage device will not receive any update from system.`;
        }

        this.props.confirm(message, () => {
            this.setState({loading: true});
            deviceService.update(r.id, {is_manage}).then(rs => {
                this.props.notification({message: 'Success', type: 'success'});
                this.refresh();
            }).catch(err => {
                this.props.notificationError(err);
                this.setState({loading: false});
            })
        });
    };

    refresh = (params = null) => {
        let {pagination, sorter} = params ? params : {};
        if (!pagination) {
            pagination = this.state.pagination;
        }
        if (!sorter) {
            sorter = this.state.sorter;
        }

        const filter = this.filterRef.current.getFieldsValue();

        const {filter_type, keyword, is_up} = filter;

        const query = {};

        if (keyword) {
            query[filter_type] = keyword;
        }
        if (is_up && is_up !== 'all') {
            query.is_up = is_up === `true`;
        }

        if (sorter && Object.keys(sorter).length > 0) {
            query.sorts = `${sorter.order === 'descend' ? '-' : ''}${Array.isArray(sorter.field) ? sorter.field.join('.') : sorter.field}`;
        }

        if (this.props.section === 'managed') {
            query.is_manage = true
        } else if (this.props.section === 'not-managed') {
            query.is_manage = false
        }

        this.setState({loading: true});
        const start = (pagination.current - 1) * pagination.pageSize;
        deviceService.list(start, pagination.pageSize, query).then(rs => {
            pagination.total = rs.total;
            this.setState({rows: rs.items, loading: false, pagination, sorter, filter})
        }).catch(err => {
            this.props.notificationError(err);
            this.setState({loading: false});
        });
    };

    renderFilter = () => {
        const initialValues = {
            filter_type: 'hostname',
            keyword: '',
            is_up: 'all',
        }

        return <Form layout="vertical" initialValues={initialValues} onValuesChange={() => this.refresh()}
                     ref={this.filterRef}>
            <Row>
                <Col span={12}>
                    <Row gutter={[8, 8]}>
                        <Col span={4}>
                            <Form.Item label="Status" name="is_up">
                                <Select>
                                    <Select.Option value="all">All</Select.Option>
                                    <Select.Option value="true">Up</Select.Option>
                                    <Select.Option value="false">Down</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item label="Filter by" name="filter_type">
                                <Select>
                                    <Select.Option value="hostname">Name</Select.Option>
                                    <Select.Option value="ip">IP Address</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            <Form.Item label=" " name="keyword">
                                <Input allowClear/>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label=" ">
                                <Button icon={<ReloadOutlined/>} onClick={() => this.refresh()}/>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    }

    onUpdateDevice(form) {
        const {id} = this.state.editing;
        if (id) {
            this.setState({editing: null});
            this.props.confirm(`Are you sure to update the device ?`, () => {
                this.setState({loading: true});
                deviceService.update(id, form).then(rs => {
                    this.props.notification({message: 'Success', type: 'success'});
                    this.refresh();
                }).catch(err => {
                    this.props.notificationError(err);
                    this.setState({loading: false});
                })
            });
        }
    };

    onSendCommandToDevice(form) {
        const {id} = this.state.sendingCommand;
        if (id) {
            this.setState({sendingCommand: null});
            this.props.confirm(`Are you sure to send command to the device ?`, () => {
                this.setState({loading: true});
                deviceService.command(id, form).then(rs => {
                    this.props.notification({message: 'Success', type: 'success'});
                    this.refresh();
                }).catch(err => {
                    this.props.notificationError(err);
                    this.setState({loading: false});
                })
            });
        }
    };

    render() {
        const {section} = this.props;
        const {pagination, loading, rows, editing, rooms, showing, sendingCommand} = this.state;

        const table = <Row gutter={[8, 8]} className="overflow-x">
            <Col span={24}>
                {this.renderFilter()}
            </Col>
            <Col span={24}>
                <Table
                    bordered
                    rowKey={'id'}
                    footer={(data) => <span>
                        Showing {data.length} record(s) of total {pagination.total}
                    </span>}
                    columns={this.columns}
                    loading={loading}
                    dataSource={rows}
                    pagination={pagination}
                    onChange={(pagination, filters, sorter) => {
                        this.refresh({pagination, filters, sorter});
                    }}
                />
            </Col>
        </Row>;

        /*const extra = <Link to={'/admin/devices/create'}>
            <Button type="primary">Add</Button>
        </Link>*/

        return <PageContent title="Devices">

            <Tabs activeKey={section}>
                <Tabs.TabPane tab={<Link to={`/admin/devices/managed`}>Managed</Link>} key="managed">
                    {table}
                </Tabs.TabPane>
                <Tabs.TabPane tab={<Link to={`/admin/devices/not-managed`}>Unmanaged</Link>} key="not-managed">
                    {table}
                </Tabs.TabPane>
            </Tabs>

            <Modal title="Update Device" visible={!!editing} footer={null}
                   onCancel={() => this.setState({editing: null})}>

                <Form layout="vertical" initialValues={{name: editing?.name, room: editing?.room?.id}}
                      onFinish={(form) => this.onUpdateDevice(form)}>
                    <Form.Item label="Name" name="name" rules={[{
                        required: true,
                        message: 'Name is require',
                    }]}>
                        <Input/>
                    </Form.Item>

                    <Form.Item label="Room/Desk" name="room">
                        <RoomsSelectors rooms={rooms}/>
                    </Form.Item>

                    <Form.Item style={{textAlign: 'right'}}>
                        <Button type="primary" htmlType="submit">
                            Update
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

            <Modal title="Send Command" visible={!!sendingCommand} footer={null}
                   onCancel={() => this.setState({sendingCommand: null})}>

                <Form layout="vertical" onFinish={(form) => {
                    const d = {
                        action: form.action,
                        params: {
                            ...form
                        }
                    }
                    this.onSendCommandToDevice(d)
                }}>
                    <Form.Item label="Action" name="action" rules={[{
                        required: true,
                        message: 'Action is require',
                    }]}>
                        <Select>
                            <Select.Option value="SET_COLOR">SET COLOR</Select.Option>
                        </Select>
                    </Form.Item>

                    <Form.Item label="Color" name="color">
                        <Input/>
                    </Form.Item>

                    <Form.Item style={{textAlign: 'right'}}>
                        <Button type="primary" htmlType="submit">
                            Send
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>

            <Drawer visible={showing} onClose={() => this.setState({showing: null})} width={520}
                    title={showing?.name}
            >
                <DeviceStatusScreen device={showing}/>
            </Drawer>
        </PageContent>
    }

    componentDidMount() {
        roomService.list()
            .then(rs => this.setState({rooms: rs.items}))
            .catch(err => this.props.notificationError(err));

        this.refresh();
    }
}

export default connect(null, CommonDispatcher)(Container);
