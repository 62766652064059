import packageJson from '../../package.json'

export const APP = {
    NAME: process.env.REACT_APP_NAME ? process.env.REACT_APP_NAME : 'SMART MEETING ROOM SYSTEM',
    APP_POWERED_BY: process.env.REACT_APP_COMPANY_NAME,
    APP_POWERED_BY_WEBSITE: process.env.REACT_APP_COMPANY_WEBSITE,
    VERSION: packageJson.version
}

export const EVENT_TYPE = {
    ENTRY_ALLOW: 'entry_allow',
    ENTRY_DENIED: 'entry_denied',
    DEVICE_DISCONNECTED: 'device_disconnect',

    NEW_DEVICE: 'new_device',
    DEVICE_IP_CHANGED: 'device_ip_change',
};

export const PERSON_TYPE = {
    PERSONNEL: 'personnel',
    GUEST: 'guest',
};

export const DEVICE_TYPES = {
    ANDROID_V1: 'android_v1',
    ANDROID: 'android',
    JOAN: 'joan',
    WEB_CLIENT: 'web_client',
    WC_JOAN: 'web_client_joan'
};


export const REPORT_TYPES = {
    BOOKINGS: 'bookings',
}

export const SCHEDULER_TYPES = {
    HOURLY: 'hourly',
    DAILY: 'daily',
    WEEKLY: 'weekly',
    MONTHLY: 'monthly'
}
export const REPORT_TARGET_TYPES = {
    TIMESPAN: 'timespan',
    TIMESTAMPS: 'timestamps'
}
export const REPORT_TIMESPAN_TYPES = {
    LAST_MONTH: 'last_month',
    LAST_WEEK: 'last_week',
    YESTERDAY: 'yesterday',
    TODAY: 'today'
}

export const USER_TYPES = {
    SUPER_ADMIN: 'super_admin',
    ADMIN: 'admin',
    IT: 'it',
    STAFF: 'staff',
}

export const AUTH_METHODS = {
    LOCAL: 'local',
    LDAP: 'ldap',
    GOOGLE: 'google',
    MICROSOFT: 'microsoft'
}

export const SETTING_KEYS = {
    DATA: {
        INIT_DATE: 'data.init_date',
    },
    DEVICE: {
        COMPANY_NAME: 'device.company_name',
        LOGO: 'device.logo',
        APP: {
            APK_VERSION: 'device.app.apk_version',
            APK_LINK: 'device.app.apk_link',
        },
        HEARTBEAT_THRESHOLD_MINUTES: 'device.heartbeat_threshold_minutes',
        NTP_SERVER1: 'device.ntp_server1',
        ACTIONS_REQUIRED_PIN: {
            BOOKING: {
                EXTEND: 'device.action_required_pin.extend',
                CHECKIN: 'device.action_required_pin.checkin',
                CHECKOUT: 'device.action_required_pin.checkout',
            },
            FACILITIES: {
                REPORT: 'device.action_required_pin.facilities.report',
            },
        },
    },
    SYSTEM: {
        COMPANY: {
            NAME: 'system.company.name',
            SUPPORT_EMAIL: 'system.company.support_email',
        }
    },
    AUTH: {
        METHOD: 'auth.method',
        SSO: {
            GOOGLE: {
                CLIENT_ID: 'auth.sso.google.client_id',
            },
            MICROSOFT: {
                CLIENT_ID: 'auth.sso.microsoft.client_id',
                TENANT_ID: 'auth.sso.microsoft.tenant_id',
            },
            LDAP: {
                HOST: 'auth.sso.ldap.host',
                PORT: 'auth.sso.ldap.port',
                BASE_DN: 'auth.sso.ldap.base_dn',
                USER_FILTER: 'auth.sso.ldap.user_filter',
                BIND_ATTR: 'auth.sso.ldap.bind_attr',
                LOGIN_ATTR: 'auth.sso.ldap.login_attr',
                AUTH_USERNAME: 'auth.sso.ldap.auth_username',
                AUTH_PASSWORD: 'auth.sso.ldap.auth_password',
            },
        },
        DASHBOARD_PANEL: {
            TOKEN: 'auth.dashboard_panel.token',
        },
    },
    BOOKING: {
        MODE: 'booking.mode',
        TIME_STEP_MINUTES: 'booking.time_step_minutes',
        AUTO_RELEASE_GRACE_MINUTES: 'booking.auto_release_grace_minutes',
        IS_ALLOWED_BOOK_ON_BEHALF: 'booking.is_allowed_book_on_behalf',
        CALENDER: {
            GOOGLE: {
                SITE_VERIFICATION_CODE: 'booking.calender.google.site-verification-code',
                CLIENT_ID: 'booking.calender.google.client_id',
                CLIENT_SECRET: 'booking.calender.google.client_secret',
                PROJECT_ID: 'booking.calender.google.project_id',
                CREDENTIAL_FILE: 'booking.calender.google.credential_file',
            },
            MICROSOFT: {
                CLIENT_ID: 'booking.calender.microsoft.client_id',
                CLIENT_SECRET: 'booking.calender.microsoft.client_secret',
                TENANT_ID: 'booking.calender.microsoft.tenant_id',
                REDIRECT_URI: 'booking.calender.microsoft.redirect_uri',
                AUTHORITY: 'booking.calender.microsoft.authority'
            },
        },
    },
    NOTIFICATION: {
        ROOM_FACILITY: {
            RECEIVER_EMAIL: 'notification.room_facility.receiver_email',
        },
        DEVICE: {
            RECEIVER_EMAIL: 'notification.device.receiver_email',
            LOW_BATTERY: {
                THRESHOLD_PERCENT: 'notification.device.low_battery.threshold_percent',
                ENABLE: 'notification.device.low_battery.enable',
            },
            DOWN: {
                THRESHOLD_MINUTES: 'notification.device.down.threshold_minutes',
                ENABLE: 'notification.device.down.enable',
            },
            UP: {
                ENABLE: 'notification.device.up.enable',
            },
        },
        BOOKING: {
            CANCELLED: {
                ENABLE: 'notification.booking.cancelled.enable',
            },
            UPCOMING: {
                BEFORE_MINUTES: 'notification.booking.upcoming.before_minutes',
                ENABLE: 'notification.booking.upcoming.enable',
            },
            ADMIN: {
                EMAIL: 'notification.booking.admin.email',
                ENABLE: 'notification.booking.admin.enable',
            }
        },
    },
    MAILER: {
        SENDER_NAME: 'mailer.sender_name',
        SENDER_EMAIL: 'mailer.sender_email',
        TYPE: 'mailer.type',
        HOST: 'mailer.host',
        PORT: 'mailer.port',
        SSL: 'mailer.ssl',
        USERNAME: 'mailer.user',
        PASSWORD: 'mailer.pass',
        API_KEY: 'mailer.api_key',
        DOMAIN: 'mailer.domain',
        UNSECURE_TLS: 'mail.unsecure_tls',
    },
}

export const ROOM_CALENDER_API_TYPES = {
    GOOGLE: 'google',
    MICROSOFT: 'microsoft'
}

export const MAILER_TYPES = {
    GENERIC: 'generic',
    // MAILGUN: 'mailgun'
}

export const EVENT_SEVERITIES = {
    DEBUG: 'DEBUG',
    INFO: 'INFO',
    NOTICE: 'NOTICE',
    WARNING: 'WARNING',
    ERROR: 'ERROR',
    CRITICAL: 'CRITICAL',
    ALERT: 'ALERT',
    EMERGENCY: 'EMERGENCY',
}

export const SYSTEM_BOOKING_MODES = {
    LOCAL: 'local',
    GOOGLE: 'google',
    MICROSOFT: 'microsoft'
}

export const BOOKING_STATUS = {
    NEW: 'new',
    ONGOING: 'ongoing',
    ENDED: 'ended',
    CHECKED_IN: 'checked_in',
    CHECKED_OUT: 'checked_out',
    CANCELLED: 'cancelled',
};


export const ROOM_TYPES = {
    ROOM: 'room',
    DESK: 'desk',
};
